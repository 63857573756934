import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { AppDispatch } from 'app/store';
import { ShardList } from 'components';
import { getShards, fetchShards, getActiveShardKey, getIsAuthenticated } from 'utilities';

export const SelectShard = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const shards = useSelector(getShards);
  const activeShardKey = useSelector(getActiveShardKey);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchShards());
    }
  }, [dispatch, isAuthenticated]);

  if (activeShardKey) {
    return <Navigate to="/" />;
  }

  return <ShardList shards={shards} forLogin={true} />;
};
