import React, { useState } from 'react';
import shortid from 'shortid';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { IAssociatedUtility, IAssociatedUtilityFormValues } from 'types';

import { AssociatedUtilityItem } from '..';
import {
  createAssociatedUtility,
  deleteAssociatedUtility,
  updateAssociatedUtility
} from '../../../effects';

type AssociatedUtilitiesListProps = {
  associatedUtilities: IAssociatedUtility[];
};

export const AssociatedUtilitiesList = (props: AssociatedUtilitiesListProps) => {
  const [associatedUtilities, setAssociatedUtilities] = useState<IAssociatedUtility[]>(props.associatedUtilities);

  const dispatch = useDispatch<AppDispatch>();

  function updateAssociatedUtilityById(id: string, values: IAssociatedUtility) {
    const index = associatedUtilities.findIndex(associatedUtility => associatedUtility.id === id);

    const updatedAssociatedUtilities = [
      ...associatedUtilities.slice(0, index),
      {
        ...associatedUtilities[index],
        ...{
          ...values
        }
      },
      ...associatedUtilities.slice(index + 1)
    ];

    setAssociatedUtilities(updatedAssociatedUtilities);
  }

  async function onSave(isNew: boolean, id: string, values: IAssociatedUtilityFormValues) {
    if (isNew) {
      const response = await dispatch(createAssociatedUtility(values));
      return updateAssociatedUtilityById(id, {
        ...values,
        id: response.id,
        isNew: false
      });
    } else {
      await dispatch(
        updateAssociatedUtility({
          id,
          ...values
        })
      );
      return updateAssociatedUtilityById(id, {
        id,
        ...values
      });
    }
  }

  async function onDelete(id: string) {
    const index = associatedUtilities.findIndex(associatedUtility => associatedUtility.id === id);
    const updatedAssociatedUtilities = associatedUtilities.filter((_associatedUtility, i) => i !== index);

    await dispatch(deleteAssociatedUtility(id));
    return setAssociatedUtilities(updatedAssociatedUtilities);
  }

  function handleAdd() {
    setAssociatedUtilities([
      ...associatedUtilities,
      {
        id: shortid.generate(),
        name: '',
        utilityInfoApiId: '',
        utilityInfoApiProvider: '',
        canDelete: true,
        isNew: true
      }
    ]);
  }

  return (
    <>
      {associatedUtilities.map(associatedUtility => (
        <AssociatedUtilityItem key={associatedUtility.id} {...associatedUtility} onDelete={onDelete} onSave={onSave} />
      ))}

      <div className="text-center">
        <Button color="link" className="text-bold" onClick={handleAdd}>
          Add New Associated Utility
        </Button>
      </div>
    </>
  );
}
