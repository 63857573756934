import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

export const RedirectsCard = (props: any): JSX.Element => {
  const { handleChange, isSubmitting, residentialNoProjectsInAreaUrl } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Redirects</CardHeader>
      <CardBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="navigation.residentialNoProjectsInAreaUrl" className="font-weight-bold">
                residentialNoProjectsInAreaUrl
              </Label>

              <Input
                disabled={isSubmitting}
                id="navigation.residentialNoProjectsInAreaUrl"
                name="navigation.residentialNoProjectsInAreaUrl"
                type="text"
                onChange={handleChange}
                value={residentialNoProjectsInAreaUrl}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
