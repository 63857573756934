import React from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Input } from 'reactstrap';
import { sentenceCase } from 'change-case';

import { ISettingsCreditCheck } from 'types';

import { Checkbox } from '../../components';

export type ResidentialCustomersCardProps = {
  creditCheck: ISettingsCreditCheck;
  handleChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const ResidentialCustomersCard = (props: ResidentialCustomersCardProps) => {
  const {
    creditCheck,
    handleChange,
    setFieldValue
  } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Residential Customers</CardHeader>
      <CardBody>
        <Checkbox
          field="creditCheck.askForFullSsn"
          checked={creditCheck.askForFullSsn}
          setFieldValue={setFieldValue}
          disabled={creditCheck.askForLast4}
        />
        <Checkbox
          field="creditCheck.askForLast4"
          checked={creditCheck.askForLast4}
          disabled={creditCheck.askForFullSsn}
          setFieldValue={setFieldValue}
        />
        <Checkbox field="creditCheck.askForDob" checked={creditCheck.askForDob} setFieldValue={setFieldValue} />
        <h5 className="mt-3 mb-3">Transunion</h5>
        <Checkbox
          field="creditCheck.transunion.useProductionUrl"
          checked={creditCheck.transunion.useProductionUrl}
          setFieldValue={setFieldValue}
        />
        <Checkbox
          field="creditCheck.transunion.useProductionEnvironment"
          checked={creditCheck.transunion.useProductionEnvironment}
          setFieldValue={setFieldValue}
        />
        <FormGroup className="mt-2" row>
          <Label xs={12} for="status" className="font-weight-bold">
            {sentenceCase('subscriberCode')}
          </Label>
          <Col xs={12}>
            <Input
              name="creditCheck.transunion.subscriberCode"
              type="text"
              value={creditCheck.transunion.subscriberCode}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mt-2" row>
          <Label xs={12} for="status" className="font-weight-bold">
            {sentenceCase('password')}
          </Label>
          <Col xs={12}>
            <Input
              name="creditCheck.transunion.password"
              type="text"
              value={creditCheck.transunion.password}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
