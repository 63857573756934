import React from 'react';
import orderBy from 'lodash/orderBy';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { ContentWrapper } from 'components';
import { useAssociatedUtilities } from 'hooks';
import { IAssociatedUtility, Route } from 'types';

import { AssociatedUtilitiesList } from './components';

export const AssociatedUtilities = (props: Route): JSX.Element => {
  const { data, error, loading } = useAssociatedUtilities();

  const utilities = data && data.associatedUtilities ? orderBy(
    data.associatedUtilities.map(
      (utility): IAssociatedUtility => ({
        ...utility,
        isNew: false
      })
    ),
    'name'
  ) : [];

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <Card className="mb-3">
          <CardHeader tag="h5">{props.name}</CardHeader>
          <CardBody>
            <AssociatedUtilitiesList associatedUtilities={utilities} />
          </CardBody>
        </Card>
      )}
    </ContentWrapper>
  );
}
