import React from 'react';

import { ContentWrapper } from 'components';
import { useResidentialContent } from 'hooks';

import { FaqList } from './components';


export const ResidentialFaqs = (): JSX.Element => {
  const { data, error, loading } = useResidentialContent();
  const { faqs } = data;

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(faqs && faqs.items) && <FaqList faqs={faqs.items} />}
    </ContentWrapper>
  );
};
