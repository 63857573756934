import React from 'react';
import { Form } from 'reactstrap';
import { FormikProps } from 'formik';

import { SaveButton } from 'components';
import { IAutoAllocation, PageProps } from 'types';

import { 
  DecomposerCard,
  PremisePreferencesCard,
  ProjectPreferencesCard
} from './components';

export type AutoAllocationsFormProps = PageProps<IAutoAllocation> & FormikProps<IAutoAllocation>;

export const AutoAllocationsForm = (props: AutoAllocationsFormProps): JSX.Element => {
  const { handleSubmit, isSubmitting, setFieldValue, values, handleChange } = props;

  return (
    <Form>
      <DecomposerCard
        decomposer={values.decomposer}
        setFieldValue={setFieldValue}
      />

      <ProjectPreferencesCard
        handleChange={handleChange}
        preferenceWeights={values.preferenceWeights}
      />

      <PremisePreferencesCard
        handleChange={handleChange}
        preferenceWeights={values.preferenceWeights}
      />

      <SaveButton
        disabled={Boolean(isSubmitting)}
        onSubmit={() => handleSubmit()}
        text={isSubmitting ? 'Saving...' : 'Save'}
      />
    </Form>
  );
};
