import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { State } from 'api';

export const RemittanceCard = (props: any): JSX.Element => {
  const { handleChange, isSubmitting, remittance } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Remittance</CardHeader>
      <CardBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.remittance.address" className="font-weight-bold">
                Address
              </Label>

              <Input
                id="contactInfo.remittance.address"
                disabled={isSubmitting}
                name="contactInfo.remittance.address"
                type="text"
                onChange={handleChange}
                value={remittance.address}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.remittance.address2" className="font-weight-bold">
                Address Line 2
              </Label>

              <Input
                id="contactInfo.remittance.address2"
                disabled={isSubmitting}
                name="contactInfo.remittance.address2"
                type="text"
                onChange={handleChange}
                value={remittance.address2}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.remittance.city" className="font-weight-bold">
                City
              </Label>

              <Input
                id="contactInfo.remittance.city"
                disabled={isSubmitting}
                name="contactInfo.remittance.city"
                type="text"
                onChange={handleChange}
                value={remittance.city}
              />
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.remittance.state" className="font-weight-bold">
                State
              </Label>

              <select
                className="form-control"
                disabled={isSubmitting}
                id="contactInfo.remittance.state"
                name="contactInfo.remittance.state"
                onChange={handleChange}
                value={remittance.state}
              >
                <option value="">Select a State&hellip;</option>
                {Object.keys(State)
                  .map(id => parseInt(id))
                  .map(id => (
                    <option key={id} value={(State as any)[id].abbr}>
                      {(State as any)[id].name}
                    </option>
                  ))}
              </select>
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.remittance.zipCode" className="font-weight-bold">
                Zip Code
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.remittance.zipCode"
                name="contactInfo.remittance.zipCode"
                type="text"
                onChange={handleChange}
                value={remittance.zipCode}
              />
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.remittance.phoneNumber" className="font-weight-bold">
                Phone Number
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.remittance.phoneNumber"
                name="contactInfo.remittance.phoneNumber"
                type="text"
                onChange={handleChange}
                value={remittance.phoneNumber}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
