import { useToasts } from 'react-toast-notifications';
import { api } from 'app/store';
import { ProfileFormValues } from 'types';

export function useUpdateAccount(userId: any) {
  const { addToast } = useToasts();

  function onSubmit(values: ProfileFormValues) {
    return api.account.updateAccount(userId, values).then(
      () => addToast('Profile saved successfully.', { appearance: 'success' }),
      () => addToast('There was an error saving the content. Please try again.', { appearance: 'error' })
    );
  }

  return {
    onSubmit,
  };
}
