import React from 'react';
import { Button, FormGroup, Label, Input, Card, CardHeader, CardBody, Col } from 'reactstrap';
import { faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons';

import { INavigationItem } from 'types';
import { Icon } from 'components';

export type SidebarNavigationCardProps = {
  isSubmitting: boolean;
  setFieldValue: any;
  sidebarItems: INavigationItem[];
};

export const SidebarNavigationCard = (props: SidebarNavigationCardProps): JSX.Element => {
  const { isSubmitting, setFieldValue, sidebarItems } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Sidebar Navigation</CardHeader>

      <CardBody>
        <FormGroup>
          <Label for="navigation.customerSidebar" className="font-weight-bold">
            Navigation Sidebar
          </Label>

          <Col sm={12}>
            {sidebarItems &&
              Boolean(sidebarItems.length) &&
              sidebarItems.map((item, index) => (
                <FormGroup row className="mb-2" key={index}>
                  <Label sm={2} for={`sidebar-title${index}`} className="font-weight-bold">
                    Title
                  </Label>

                  <Col className="pb-1" sm={10}>
                    <Input
                      disabled={isSubmitting}
                      id={`sidebar-title-${index}`}
                      name={`sidebar-title-${index}`}
                      type="text"
                      onChange={event => {
                        sidebarItems[index].title = event.currentTarget.value;
                        setFieldValue('navigation.customerSidebar', sidebarItems);
                      }}
                      value={item.title}
                    />
                  </Col>

                  <Label sm={2} for={`sidebar-title${index}`} className="font-weight-bold">
                    Icon
                  </Label>

                  <Col className="pb-1" sm={10}>
                    <Input
                      disabled={isSubmitting}
                      id={`sidebar-icon-${index}`}
                      name={`sidebar-icon-${index}`}
                      type="text"
                      onChange={event => {
                        sidebarItems[index].icon = event.currentTarget.value;
                        setFieldValue('navigation.customerSidebar', sidebarItems);
                      }}
                      value={item.icon}
                    />
                  </Col>

                  <Label sm={2} for={`sidebar-title${index}`} className="font-weight-bold">
                    Url
                  </Label>

                  <Col className="pb-1" sm={10}>
                    <Input
                      disabled={isSubmitting}
                      id={`sidebar-url-${index}`}
                      name={`sidebar-url-${index}`}
                      type="text"
                      onChange={event => {
                        sidebarItems[index].url = event.currentTarget.value;
                        setFieldValue('navigation.customerSidebar', sidebarItems);
                      }}
                      value={item.url}
                    />
                  </Col>

                  <Col className="text-right" sm={12}>
                    <Button
                      onClick={() => {
                        sidebarItems.splice(index, 1);

                        setFieldValue('navigation.customerSidebar', sidebarItems);
                      }}
                      color="danger"
                      className="mt-1"
                      outline
                    >
                      <Icon icon={faTrash} /> Remove
                    </Button>
                  </Col>
                </FormGroup>
              ))}
          </Col>

          <Col sm={12}>
            <Button
              onClick={() => {
                sidebarItems.push({
                  title: '',
                  icon: '',
                  url: ''
                });

                setFieldValue('navigation.customerSidebar', sidebarItems);
              }}
              color="primary"
            >
              <Icon icon={faPlus} /> Add Item
            </Button>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
