import { faCube } from '@fortawesome/pro-duotone-svg-icons';

import { AssociatedUtilities } from 'pages';
import { Route } from 'types';

export const baseRoute = 'entities';
export const associatedUtilitiesRoute = 'associated-utilities';

export const routes: Route[] = [
  {
    component: AssociatedUtilities,
    key: 'associated-utilities',
    name: 'Associated Utilities',
    path: associatedUtilitiesRoute,
    icon: faCube
  }
];
