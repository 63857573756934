import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icon } from 'components';

import { NavIcon, NavText, StyledRouterNavLink } from './MenuItem.styles';

export type MenuItemProps = {
  to: string;
  icon?: IconProp;
  text: string;
  tag?: React.ElementType<any>;
  exact?: boolean;
}

export const MenuItem = (props: any): JSX.Element => {
  const { to, text } = props;
  const exact = props.exact ? props.exact : false;
  const icon = props.icon ? props.icon : false;
  const tag = props.tag ? props.tag : StyledRouterNavLink;

  return (
    <NavItem>
      <NavLink
        exact={exact}
        tag={tag}
        to={to}
        className="d-flex align-items-center"
      >
        {icon && 
          <NavIcon>
            <Icon icon={icon} />
          </NavIcon>
        }
        <NavText>{text}</NavText>
      </NavLink>
    </NavItem>
  );
};
