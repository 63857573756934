import React from 'react';
import { Card, CardHeader, CardBody, Col, Row, Alert } from 'reactstrap';
import { sentenceCase } from 'change-case';
import classNames from 'classnames';
import { FormikProps } from 'formik';

import { PercentRange, PercentRow } from 'components';
import { IAutoAllocation } from 'types';


export type ProjectPreferencesCardProps = {
  preferenceWeights: IAutoAllocation["preferenceWeights"];
  handleChange: FormikProps<IAutoAllocation>["handleChange"];
}

export const ProjectPreferencesCard = (props: ProjectPreferencesCardProps): JSX.Element => {
  const { handleChange, preferenceWeights } = props;

  const projectTotal =
    preferenceWeights.projects.adjacencyCount +
    preferenceWeights.projects.annualUsage +
    preferenceWeights.projects.billCreditDelta +
    preferenceWeights.projects.creditRate;
  const projectRemaining = 100 - projectTotal;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Project Preferences</CardHeader>

      <CardBody>
        <Alert color="info">These values must add up to 100.</Alert>

        <PercentRow
          text={sentenceCase('adjacencyCount')}
          value={preferenceWeights.projects.adjacencyCount}
        >
          <PercentRange
              onChange={handleChange}
              max={preferenceWeights.projects.adjacencyCount + projectRemaining}
              value={preferenceWeights.projects.adjacencyCount}
              name="preferenceWeights.projects.adjacencyCount"
              width={(100 * (preferenceWeights.projects.adjacencyCount + projectRemaining)) / 100}
            />
        </PercentRow>

        <PercentRow
          text={sentenceCase('annualUsage')}
          value={preferenceWeights.projects.annualUsage}
        >
          <PercentRange
            max={preferenceWeights.projects.annualUsage + projectRemaining}
            name="preferenceWeights.projects.annualUsage"
            onChange={handleChange}
            value={preferenceWeights.projects.annualUsage}
            width={(100 * (preferenceWeights.projects.annualUsage + projectRemaining)) / 100}
          />
        </PercentRow>

        <PercentRow
          text={sentenceCase('billCreditDelta')}
          value={preferenceWeights.projects.billCreditDelta}
        >
          <PercentRange
            max={preferenceWeights.projects.billCreditDelta + projectRemaining}
            name="preferenceWeights.projects.billCreditDelta"
            onChange={handleChange}
            value={preferenceWeights.projects.billCreditDelta}
            width={(100 * (preferenceWeights.projects.billCreditDelta + projectRemaining)) / 100}
          />
        </PercentRow>

        <PercentRow
          text={sentenceCase('creditRate')}
          value={preferenceWeights.projects.creditRate}
        >
          <PercentRange
            max={preferenceWeights.projects.creditRate + projectRemaining}
            name="preferenceWeights.projects.creditRate"
            onChange={handleChange}
            value={preferenceWeights.projects.creditRate}
            width={(100 * (preferenceWeights.projects.creditRate + projectRemaining)) / 100}
          />
        </PercentRow>

        <Row>
          <Col xs="3" className="font-weight-bold">
            Total
          </Col>

          <Col
            xs="2"
            className={classNames({
              'text-right': true,
              'font-weight-bold': true,
              'text-success': projectTotal === 100,
              'text-danger': projectTotal < 100
            })}
          >
            {projectTotal}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
