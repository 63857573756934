import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button as SubmitButton } from 'components';
import { faEllipsisV, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonQuestionsWrapper, Question } from './CommonQuestions.styles';
// import HowCanISaveMoney from './assets/HowCanISaveMoney.png';
import HowDoesCommunitySolarWork from './assets/HowDoesCommunitySolarWork.png';
import WhyWillIReceiveTwoBills from './assets/WhyWillIReceiveTwoBills.png';
import HowDoesThisHelpTheEnvironment from './assets/HowDoesThisHelpTheEnvironment.png';
import WhatIfIMove from './assets/WhatIfIMove.png';
import WhatIfIHaveQuestions from './assets/WhatIfIHaveMoreQuestions.png';
import IsThisTooGoodToBeTrue from './assets/IsThisTooGoodToBeTrue.png';
import WhyTheWait from './assets/WhyTheWait.png';
import { useToasts } from 'react-toast-notifications';

const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

const items = [
  {
    title: 'How does community solar work?',
    image: HowDoesCommunitySolarWork,
    question: 'HowDoesCommunitySolarWork',
  },
  {
    title: 'Why will I receive two bills?',
    image: WhyWillIReceiveTwoBills,
    question: 'WhyWillIReceiveTwoBills',
  },
  {
    title: 'How does my subscription help the environment?',
    image: HowDoesThisHelpTheEnvironment,
    question: 'HowDoesThisHelpTheEnvironment',
  },
  {
    title: 'Is this too good to be true?',
    image: IsThisTooGoodToBeTrue,
    question: 'IsThisToGoodToBeTrue',
  },
  {
    title: 'What if I move or change utilities?',
    image: WhatIfIMove,
    question: 'WhatIfIMove',
  },
  {
    title: 'Why the wait between project kick-off and solar credits appearing on my bill?',
    image: WhyTheWait,
    question: 'WhyTheWait',
  },
  //   {
  //     title: 'How can I save even more money?',
  //     image: HowCanISaveMoney,
  //     question: 'HowCanISaveMoney',
  //   },
  {
    title: 'What if I have more questions?',
    image: WhatIfIHaveQuestions,
    question: 'WhatIfIHaveQuestions',
  },
];

export const CommonQuestions = ({ data, submitUpdate, setCommonQuestionsUpdate, aboutUsUpdate }: any) => {
  const { addToast } = useToasts();
  const [list, setList] = useState(items);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  const [values, setValues] = useState(data?.commonQuestions);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let valuesToSend = [];
    if (!values.length) {
      valuesToSend = list
        .map((item, index) => {
          if (index < 5) {
            return item.question;
          } else return null;
        })
        .filter((item) => item !== null);
    } else {
      valuesToSend = data?.commonQuestions?.map((item: { question: any }) => {
        return item.question;
      });
      const sortedItems = items.sort((a, b) => {
        const orderA =
          data.commonQuestions.find((q: { question: string }) => q.question === a.question)?.order || Infinity;
        const orderB =
          data.commonQuestions.find((q: { question: string }) => q.question === b.question)?.order || Infinity;
        return orderA - orderB;
      });
      const remainingItems = sortedItems.filter(
        (item) => !data.commonQuestions.some((q: { question: string }) => q.question === item.question)
      );
      const finalItems = [
        ...sortedItems.filter((item) =>
          data.commonQuestions.some((q: { question: string }) => q.question === item.question)
        ),
        ...remainingItems,
      ];
      setList(finalItems);
    }
    setValues(valuesToSend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onDragStart = (event: {
    currentTarget: { dataset: { position: any } };
    dataTransfer: { setData: (arg0: string, arg1: string) => void };
  }) => {
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      // @ts-ignore
      draggedFrom: initialPosition,
      isDragging: true,
      //   @ts-ignore
      originalOrder: list,
    });

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (event: { preventDefault: () => void; currentTarget: { dataset: { position: any } } }) => {
    // in order for the onDrop event to fire, we have to cancel out this one
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);

    // @ts-ignore
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    // @ts-ignore
    newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        // @ts-ignore
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = (event: any) => {
    setList(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  const handleCheckboxChange = (e: { target: { value: any; checked: any } }) => {
    const { value, checked } = e.target;

    // Update the list of selected values based on the checked state
    if (checked) {
      setValues([...values, value]);
    } else {
      setValues(values.filter((item: any) => item !== value));
    }
  };

  const handleSubmit = () => {
    setIsSaving(true);
    let questionValues;
    if (dragAndDrop.updatedOrder.length) {
      questionValues = dragAndDrop.updatedOrder
        .map((item) => {
          // @ts-ignore
          if (values.includes(item.question)) {
            return {
              // @ts-ignore
              question: item.question,
            };
          }
          return null;
        })
        .filter((item) => item !== null)
        .map((item, index) => ({
          ...item,
          order: index + 1,
        }));
    } else {
      questionValues = values.map((item: string, index: number) => ({
        question: item,
        order: index + 1,
      }));
    }
    setCommonQuestionsUpdate(questionValues);
    const updatedValues = {
      ...data,
      aboutUs: aboutUsUpdate,
      commonQuestions: questionValues,
    };
    submitUpdate(updatedValues).then(() => {
      addToast('Common questions updated successfully.', { appearance: 'success' });
      setIsSaving(false);
    });
  };

  const toggle = () => setActiveQuestion(null);

  return (
    <Card className="mt-3">
      <CardHeader className="font-weight-bold">Common Questions</CardHeader>
      <CardBody>
        <p className="font-weight-bold mb-0">Select a total of 4-6 questions (drag and drop to reorder)</p>
        <CommonQuestionsWrapper className="my-3">
          {list.map((item, index) => {
            return (
              <Question
                key={index}
                data-position={index}
                draggable
                //@ts-ignore
                onDragStart={onDragStart}
                //@ts-ignore
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragLeave={onDragLeave}
                className={dragAndDrop && dragAndDrop.draggedTo === Number(index) ? 'dropArea' : ''}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <FontAwesomeIcon icon={faEllipsisV} className="drag-icon" style={{ marginRight: '1px' }} />
                      <FontAwesomeIcon icon={faEllipsisV} className="drag-icon" />
                    </div>
                    <input
                      type="checkbox"
                      value={item.question}
                      checked={values.includes(item.question)}
                      onChange={handleCheckboxChange}
                    />
                    <p className="mb-0">{item.title}</p>
                    {item.question === 'WhyWillIReceiveTwoBills' ? (
                      <div className="dual-billed-pill">Dual Billed</div>
                    ) : null}
                  </div>
                  {/* @ts-ignore */}
                  <FontAwesomeIcon icon={faExpand} className="expand-icon" onClick={() => setActiveQuestion(item)} />
                </div>
              </Question>
            );
          })}
        </CommonQuestionsWrapper>
        <div className="d-flex justify-content-end">
          <SubmitButton
            block
            disabled={values.length > 6 || values.length < 4 || isSaving}
            color="primary"
            type="submit"
            onClick={handleSubmit}
            className="w-auto"
          >
            {isSaving ? 'Saving...' : 'Save'}
          </SubmitButton>
        </div>
      </CardBody>
      <Modal isOpen={Boolean(activeQuestion)} size="lg" backdrop={true} centered={true} toggle={toggle}>
        <ModalHeader className="position-relative">
          {/* @ts-ignore */}
          <span>{activeQuestion?.title}</span>
          <FontAwesomeIcon
            icon={faTimes}
            className="close-icon"
            style={{ position: 'absolute', right: '1rem', cursor: 'pointer' }}
            // @ts-ignore
            onClick={() => setActiveQuestion(null)}
          />
        </ModalHeader>
        <ModalBody>
          {/* @ts-ignore */}
          <img src={activeQuestion?.image} alt={activeQuestion?.title} style={{ maxWidth: '100%' }} />
        </ModalBody>
      </Modal>
    </Card>
  );
};
