import { Api } from 'api';
import { IAddUserValues, ProfileFormValues } from 'types';
import { BaseService } from './BaseService';

export class AccountService extends BaseService {
  constructor(api: Api) {
    super(api, '/account');
  }

  public fetchUser() {
    return this.get<any>('/user');
  }

  public fetchAccount(userId: string | undefined) {
    if (userId) {
      return this.get<any>(`${this.baseUrl}/${userId}`);
    }

    return Promise.reject({
      error: true,
    });
  }

  public updateAccount(id: string, values: ProfileFormValues) {
    return this.put(this.baseUrl, {
      // @ts-ignore
      id,
      ...values,
    });
  }

  public changePassword() {
    return this.post(`${this.baseUrl}/change-password`);
  }

  public fetchUsers() {
    return this.get<any>(`${this.baseUrl}/admin-users`);
  }

  public addUser(values: IAddUserValues) {
    const emailEncoded = encodeURIComponent(values.email);
    const encoded = `${emailEncoded}&firstName=${values.firstName}&lastName=${values.lastName}`;
    return this.post(`${this.baseUrl}/invite-user?email=${encoded}`);
  }
}
