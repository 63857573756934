import React from 'react';

import { ContentWrapper } from 'components';
import { useResidentialContent } from 'hooks';

import { HomePageEditorForm } from './components';

export const HomePageEditor = (): JSX.Element => {
  const { data, error, loading } = useResidentialContent();
  const { content } = data;

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(content) && <HomePageEditorForm content={content.homepage} />}
    </ContentWrapper>
  );
};