import styled from 'styled-components';

export const App = styled.div`
  display: flex;
  align-items: stretch;
  height: 100vh;
  position: relative;
  z-index: 0;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: scroll;
  padding-top: 2rem;
  padding-bottom: 4rem;
`;
