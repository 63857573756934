import React from 'react';

import { ContentWrapper } from 'components';
import { useResidentialContent } from 'hooks';
import { PageKey } from 'types';

import { PageEditor } from './components';

type ResidentialPageProps = {
  name: string;
  pageKey: PageKey;
};

export const ResidentialPage = (props: ResidentialPageProps): JSX.Element => {
  const { name, pageKey } = props;
  const { data, error, loading } = useResidentialContent();
  const { content } = data;

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(content) && <PageEditor content={content[pageKey] || { title: undefined, body: undefined }} name={name} pageKey={pageKey} />}
    </ContentWrapper>
  );
};
