import React from 'react';
import { FormGroup, Card, CardHeader, CardBody, Col } from 'reactstrap';
import { range } from 'lodash';

import { ISettingsPasswordRequirements } from 'types';

import { Checkbox, NumberSelect } from '../../components';

export type PasswordRequirementsCardProps = {
  handleChange: any;
  passwordRequirements: ISettingsPasswordRequirements;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const PasswordRequirementsCard = (props: PasswordRequirementsCardProps) => {
  const {
    handleChange,
    passwordRequirements,
    setFieldValue
  } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Password Requirements</CardHeader>
      <CardBody>
        <NumberSelect
          value={passwordRequirements.requiredLength}
          field="passwordRequirements.requiredLength"
          onChange={handleChange}
          options={range(8, 16)}
        />
        <FormGroup row>
          <Col xs={6}>
            <Checkbox
              field="passwordRequirements.requireDigit"
              checked={passwordRequirements.requireDigit}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="passwordRequirements.requireLowercase"
              checked={passwordRequirements.requireLowercase}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="passwordRequirements.requireNonLetterOrDigit"
              checked={passwordRequirements.requireNonLetterOrDigit}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="passwordRequirements.requireUppercase"
              checked={passwordRequirements.requireUppercase}
              setFieldValue={setFieldValue}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
