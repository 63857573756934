import React, { useState } from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button } from 'reactstrap';

import { ContentWrapper } from 'components';
import { useUsers } from 'hooks';

import { AddUserModal } from './components';

export const Users = (): JSX.Element => {
  const { addNewUser, error, loading, users } = useUsers();

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  function handleCancelAddUser() {
    setIsAddUserModalOpen(false);
  }

  return (
    <ContentWrapper error={error} loading={loading}>
      {!loading && (
        <>
          <Card className="mb-3">
            <CardHeader>
              <div className="d-flex align-items-center">
                <h5 className="mb-0">Users</h5>

                <Button color="primary" className="ml-auto" onClick={() => setIsAddUserModalOpen(true)}>
                  New User
                </Button>
              </div>
            </CardHeader>

            <CardBody>
              <ListGroup>
                {users.map(user => (
                  <ListGroupItem key={user.id}>
                    {user.firstName} {user.lastName} <small className="text-muted">{user.email}</small>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>

          <AddUserModal
            isOpen={isAddUserModalOpen}
            setIsOpen={setIsAddUserModalOpen}
            onCancel={handleCancelAddUser}
            onSubmit={addNewUser}
          />
        </>
      )}
    </ContentWrapper>
  );
}
