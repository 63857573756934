import React, { useEffect } from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { State } from 'api';

export const CompanyCard = (props: any): JSX.Element => {
  const { handleChange, isSubmitting, setFieldValue, company } = props;

  // Fix for potential bad data where the state was saved as a state id instead of abbr
  // e.g. "1" instead of "AL"
  useEffect(() => {
    const state = company.state;

    if (!isNaN(parseInt(state, 10))) {
      setFieldValue('contactInfo.company.state', (State as any)[parseInt(state, 10)].abbr || '');
    }
  }, [company.state, setFieldValue]);

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Company</CardHeader>

      <CardBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.company.name" className="font-weight-bold">
                Company Name
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.company.name"
                name="contactInfo.company.name"
                type="text"
                onChange={handleChange}
                value={company.name}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.company.legalName" className="font-weight-bold">
                Legal Name
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.company.legalName"
                name="contactInfo.company.legalName"
                type="text"
                onChange={handleChange}
                value={company.legalName}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.company.address" className="font-weight-bold">
                Address
              </Label>

              <Input
                id="contactInfo.company.address"
                disabled={isSubmitting}
                name="contactInfo.company.address"
                type="text"
                onChange={handleChange}
                value={company.address}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="contactInfo.company.address2" className="font-weight-bold">
                Address Line 2
              </Label>

              <Input
                id="contactInfo.company.address2"
                disabled={isSubmitting}
                name="contactInfo.company.address2"
                type="text"
                onChange={handleChange}
                value={company.address2}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.company.city" className="font-weight-bold">
                City
              </Label>

              <Input
                id="contactInfo.company.city"
                disabled={isSubmitting}
                name="contactInfo.company.city"
                type="text"
                onChange={handleChange}
                value={company.city}
              />
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.company.state" className="font-weight-bold">
                State
              </Label>

              <select
                className="form-control"
                disabled={isSubmitting}
                id="contactInfo.company.state"
                name="contactInfo.company.state"
                onChange={handleChange}
                value={company.state}
              >
                <option value="">Select a State&hellip;</option>
                {Object.keys(State)
                  .map(id => parseInt(id))
                  .map(id => (
                    <option key={id} value={(State as any)[id].abbr}>
                      {(State as any)[id].name}
                    </option>
                  ))}
              </select>
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.company.zipCode" className="font-weight-bold">
                Zip Code
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.company.zipCode"
                name="contactInfo.company.zipCode"
                type="text"
                onChange={handleChange}
                value={company.zipCode}
              />
            </FormGroup>
          </Col>

          <Col xs={3}>
            <FormGroup>
              <Label for="contactInfo.company.phoneNumber" className="font-weight-bold">
                Phone Number
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.company.phoneNumber"
                name="contactInfo.company.phoneNumber"
                type="text"
                onChange={handleChange}
                value={company.phoneNumber}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
