import { IUseForm } from 'hooks';

interface IHandleChangeArgs<T> {
  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;
  topField: keyof T;
  form: IUseForm<T>;
}

type HandleChange = <T>(args: IHandleChangeArgs<T>) => void;

export const handleNestedChange: HandleChange = args => {
  const { event, topField, form } = args;
  event.persist();
  const { setValues, success, error, setSuccess, setError } = form;

  if (success) {
    setSuccess(false);
  }
  if (error) {
    setError(undefined);
  }

  setValues(values => {
    const input = event.target;
    if (!input) return values;

    const data = {
      ...values,
      [topField]: {
        ...values[topField],
        [input.name]: input.value
      }
    };

    return data;
  });
};
