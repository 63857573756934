import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Route } from 'types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { CONTENT_ENDPOINTS } from 'api';
import { useContent } from 'hooks';
import { ContentType, update } from '../effects';
import { IInitialDashboard } from 'types';
import { ContentWrapper } from 'components';
import { AboutUs, CommonQuestions, ProjectTimeline } from './components';

export const InitialDashboard = (props: Route) => {
  const { data, error, loading } = useContent(CONTENT_ENDPOINTS.INITIAL_DASHBOARD)();
  const [aboutUsUpdate, setAboutUsUpdate] = useState(data?.aboutUs);
  const [commonQuestionsUpdate, setCommonQuestionsUpdate] = useState(data?.commonQuestions);
  const dispatch = useDispatch<AppDispatch>();

  const submitUpdate = async (values: IInitialDashboard) =>
    await dispatch(update(CONTENT_ENDPOINTS.INITIAL_DASHBOARD, ContentType.InitialDashboard, values));

  return (
    <div>
      <h5 className="font-weight-bold">Initial Dashboard</h5>
      <ContentWrapper error={error} loading={loading}>
        <>
          <Row>
            <Col xs={12} md={6}>
              <AboutUs
                submitUpdate={submitUpdate}
                commonQuestionsUpdate={commonQuestionsUpdate}
                data={data}
                setAboutUsUpdate={setAboutUsUpdate}
              />
            </Col>
            <Col xs={12} md={6}>
              <ProjectTimeline />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CommonQuestions
                submitUpdate={submitUpdate}
                aboutUsUpdate={aboutUsUpdate}
                data={data}
                setCommonQuestionsUpdate={setCommonQuestionsUpdate}
              />
            </Col>
          </Row>
        </>
      </ContentWrapper>
    </div>
  );
};
