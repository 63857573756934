import download from 'downloadjs';
import React, { useState } from 'react';
import { Form } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { SaveButton } from 'components';
import { ITheme, PageProps } from 'types';

import { checkInvoicePreview, getInvoicePreview, createInvoicePreview } from '../../../effects';
import { PortalCard, ResidentialCard } from './components';

export const ThemeForm = (props: PageProps<ITheme> & FormikProps<ITheme>) => {
  const { onPostFile, handleSubmit, isSubmitting, setFieldValue, values, setFieldError, errors } = props;
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const handlePreview = async () => {
    setIsPreviewing(true);

    //get a ticket
    const response = await dispatch(createInvoicePreview(CONFIGURATION_ENDPOINTS.INVOICE_INVOICE_PREVIEW));

    const ticket = response.ticket;

    checkThenDownloadInvoice(ticket, async () => {
      //it should be ready now
      const fileData = await dispatch(getInvoicePreview(ticket));

      download(fileData, `invoice.pdf`, 'application/pdf');

      setIsPreviewing(false);
    });
  };

  const checkThenDownloadInvoice = async (ticket: string, callback: { (): void; (): void }) => {
    let isReady = false;
    let isDownloaded = false;

    const intervalId = setInterval(async () => {
      try {
        isReady = await dispatch(checkInvoicePreview(ticket));

        //stop the interval if it is ready
        if (isReady) {
          clearInterval(intervalId);

          //we might have other threads in-flight
          if (!isDownloaded) {
            //prevents any straggler threads from getting in here
            isDownloaded = true;

            callback();
          }
        }
      } catch (error) {
        //a 404 will land here
        const err = error as any;
        if (err.statusCode !== 404) {
          throw error;
        }
      }
    }, 1000);
  };

  return (
    <Form>
      <PortalCard
        errors={errors}
        handlePreview={handlePreview}
        invoices={values.invoices}
        isPreviewing={isPreviewing}
        isSubmitting={isSubmitting}
        onPostFile={onPostFile}
        setFieldError={setFieldError}
        setFieldValue={setFieldValue}
        theme={values.theme}
      />

      <ResidentialCard
        errors={errors}
        residential={values.residential}
        onPostFile={onPostFile}
        setFieldError={setFieldError}
        setFieldValue={setFieldValue}
      />

      <SaveButton disabled={isSubmitting} onSubmit={() => handleSubmit()} text={isSubmitting ? 'Saving...' : 'Save'} />
    </Form>
  );
};
