import React from 'react';

import { CONFIGURATION_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';
import { Route } from 'types';

import { KeysForm } from './components';

export const Keys = (props: Route): JSX.Element => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.SECRETS)();

  let values = { ...data };

  if (values) {
    if (values.stripe) {
      values.stripe = {
        ...data.stripe
      };
      //@ts-ignore
      delete values.stripe.webhookUrl;
    }

    if (values.utilityApi) {
      values.utilityApi = {
        ...data.utilityApi
      };
      //@ts-ignore
      delete values.utilityApi.webhookUrl;
    }
  }

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && Boolean(data.stripe) && Boolean(data.utilityApi) && (
        <KeysForm
          {...data}
          values={values}
          endpoint={CONFIGURATION_ENDPOINTS.SECRETS}
          name={props.name}
          stripeWebhookUrl={data.stripe.webhookUrl}
          utilityApiWebhookUrl={data.utilityApi.webhookUrl}
        />
      )}
    </ContentWrapper>
  );
};
