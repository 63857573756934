import React from 'react';
import { Form, Card, CardHeader, CardBody, FormGroup, Label, Col, Input, Badge } from 'reactstrap';
import { FormikProps } from 'formik';

import { SaveButton } from 'components';
import { IContent, PageProps } from 'types';

export const PrivacyPolicyForm = (props: PageProps<IContent> & FormikProps<IContent>) => {
  const {
    handleSubmit,
    isSubmitting,
    handleChange,
    tokens,
    values: { content }
  } = props;

  return (
    <Form>
      <Card className="mb-3">
        <CardHeader tag="h5">Privacy Policy</CardHeader>
        <CardBody>
          <FormGroup className="mt-2" row>
            <Label xs={12} for="content" className="font-weight-bold">
              Tokens
            </Label>
            <Col xs={12}>
              {(tokens as string[]).map(token => (
                <Badge className="mr-1 mb-1 text-monospace" color="secondary" key={token}>
                  {token}
                </Badge>
              ))}
            </Col>
          </FormGroup>

          <FormGroup className="mt-2" row>
            <Label xs={12} for="content" className="font-weight-bold">
              Content
            </Label>
            <Col xs={12}>
              <Input
                type="textarea"
                disabled={isSubmitting}
                name="content"
                rows="25"
                value={content}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </CardBody>
      </Card>

      <SaveButton
        disabled={isSubmitting}
        onSubmit={() => handleSubmit()}
        text={isSubmitting ? 'Saving...' : 'Save'}
      />
    </Form>
  );
};
