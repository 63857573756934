import React, { useState } from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { InputGroup, InputGroupAddon, InputGroupText, Input, UncontrolledPopover } from 'reactstrap';

import { ColorPreview } from './ColorPicker.styles';

export type ColorPickerProps = {
  color: string;
  id: string;
  onChange: ColorChangeHandler;
};

export const ColorPicker = (props: ColorPickerProps) => {
  const { color, onChange, id } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <ColorPreview onClick={() => setPopoverOpen(!popoverOpen)} color={color} id={`color-picker-${id}`} />
        </InputGroupText>
      </InputGroupAddon>

      <Input disabled value={color} />

      <UncontrolledPopover placement="top" target={`color-picker-${id}`} trigger="legacy">
        <ChromePicker color={color || '#ffffff'} onChange={onChange} />
      </UncontrolledPopover>
    </InputGroup>
  );
};
