import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { ContentWrapper } from 'components';
import { useUser, useAccount } from 'hooks';

import { ProfileForm, RequestPasswordReset } from './components';

export const AccountSettings = (): JSX.Element => {
  // allows us to get the userId to then use the useAccount hook
  const { data: userData, error: userError, loading: userLoading } = useUser();
  //   @ts-ignore
  const { data: accountData, error: accountError, loading: accountLoading } = useAccount(userData.id);

  const profileValues = {
    firstName: (accountData as any).firstName,
    lastName: (accountData as any).lastName,
    id: (accountData as any).id,
  };

  return (
    <ContentWrapper error={userError || accountError} loading={userLoading || accountLoading}>
      {!!accountData && (
        <>
          <Card className="mb-3">
            <CardHeader tag="h5">Profile</CardHeader>

            <CardBody>
              <ProfileForm initialValues={profileValues} />
            </CardBody>
          </Card>

          <Card className="mb-3">
            <CardHeader tag="h5">Reset Password</CardHeader>

            <CardBody>
              {/* @ts-ignore */}
              <RequestPasswordReset id={userData.id} />
            </CardBody>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};
