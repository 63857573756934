import React from 'react';
import { Button as RSButton, ButtonProps as RSButtonProps, Spinner } from 'reactstrap';
import classNames from 'classnames';

type ButtonProps = RSButtonProps & {
  loading?: boolean;
};

export const Button = (props: ButtonProps): JSX.Element => {
  const { asdf, foo, children, disabled, loading, block, ...rest } = props;

  return (
    <RSButton {...rest} block={block} disabled={disabled || loading}>
      {loading && (
        <Spinner
          className={classNames({
            'position-relative': true,
            'button-style': !block,
            visible: loading,
            invisible: !loading
          })}
          size="sm"
          // type="grow"
        />
      )}
      {!loading && (
        <span
          className={classNames({
            invisible: loading,
            visible: !loading,
            'span-style': !block
          })}
        >
          {children}
        </span>
      )}
    </RSButton>
  );
};
