import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { AppDispatch } from 'app/store';
import { getActiveShard, fetchShardLastReset } from 'utilities';
import { Icon } from 'components';
import { LastResetBy } from './LastResetBy';
import { ResetModal } from './ResetModal';
import { useShardReset } from 'hooks';


const StatusContainer = styled.div`
  display: flex;
  width: 320px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--info-info-100, #F5F9FC);
`;

const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
`;

const StatusHeader = styled.div`
  color: var(--neutral-black, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
`;

const FlexCardBody = styled(CardBody)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Container = styled.div`
  max-width: 775px;
`;

export const SandboxSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const activeShard = useSelector(getActiveShard);
  const [isOpen, setIsOpen] = useState(false);
  const { loading, onSubmit } = useShardReset();

  useEffect(() => {
    if (activeShard) {
      if (activeShard.isSandboxShard || activeShard.isDemoShard) {
        dispatch(fetchShardLastReset(activeShard.key));
      } else {
        navigate('/configuration/contact-information');
      }
    }
  }, [dispatch, activeShard, navigate]);

  const onModalCancel = () => {
    setIsOpen(false);
  }

  const onModalSubmit = () => {
    if (activeShard) {
      onSubmit()
        .finally(() => {
          dispatch(fetchShardLastReset(activeShard.key));
          setIsOpen(false);
        })
    }
  }

  if (!activeShard) return null;

  return (
    <Container>
      <div className="mt-2 mb-4">
        <h4>Sandbox Settings</h4>
        <div>
          {activeShard.hostname}
          <Link to={{ pathname: `https://${activeShard.hostname}` }} target='_blank' className="btn btn-link p-0 ml-1"><Icon icon={faLink} /></Link>
        </div>
      </div>
      <Card>
        <CardHeader>
          Data Reset
        </CardHeader>
        <FlexCardBody>
          <ResetContainer>
            <p>Resetting the sandbox data replaces all existing data with default data. Previous work will not be saved.</p>
            <div>
              <Button color="danger" onClick={() => setIsOpen(!isOpen)} disabled={!activeShard.canResetDatabase}>
                Reset Sandbox Data
              </Button>
            </div>
          </ResetContainer>
          <StatusContainer>
            <StatusHeader>Status</StatusHeader>
            <LastResetBy dataResetTracking={activeShard.dataResetTracking} />
          </StatusContainer>
        </FlexCardBody>
      </Card>
      <ResetModal isOpen={isOpen} onCancel={onModalCancel} onSubmit={onModalSubmit} loading={loading} />
    </Container>
  )
};
