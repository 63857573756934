import React from 'react';
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { Button } from 'components';
import { IPageBlock } from 'types';
import { useForm } from 'hooks';

import { updateHomePageForm } from '../../../effects';

type HomePageFormEditorFormProps = {
  content: IPageBlock;
};

export const HomePageFormEditorForm = (props: HomePageFormEditorFormProps) => {
  const { content } = props;

  const dispatch = useDispatch<AppDispatch>();

  function onSubmit(): any {
    return dispatch(updateHomePageForm(values));
  }

  const initialValues: Partial<IPageBlock> = {
    title: content.title
  };

  const form = useForm<Partial<IPageBlock>>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  return (
    <Form>
      {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
      {Boolean(success) && <Alert color="success">Page saved successfully.</Alert>}

      <FormGroup>
        <Label for="title" className="font-weight-bold">
          Title
        </Label>

        <Input
          disabled={isSubmitting}
          id="title"
          name="title"
          onChange={handleInputChange}
          type="text"
          value={values.title}
        />
      </FormGroup>

      <Button block loading={isSubmitting} color="primary" type="submit" onClick={handleSubmit}>
        Save
      </Button>
    </Form>
  );
};
