import React from 'react';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { Button } from 'components';
import { useForm } from 'hooks';
import { forgotPassword } from 'utilities';

export type ForgotPasswordFormValues = {
  email: string;
};

export const ForgotPasswordForm = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  function onSubmit(): any {
    return dispatch(forgotPassword(values.email));
  }

  const initialValues: ForgotPasswordFormValues = {
    email: ''
  };

  const form = useForm<ForgotPasswordFormValues>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  return (
    <Form>
      {Boolean(error) && (
        <Alert className="text-center" color="danger">
          There was an error resetting your password. Please try again.
        </Alert>
      )}

      {Boolean(success) && (
        <>
          <Alert color="info">
            Check your email for a link to reset your password. If it doesn&rsquo;t appear within a few minutes, check
            your spam folder.
          </Alert>

          <Button block color="primary" className="font-weight-bold" tag={Link} to="/login">
            Return to sign in
          </Button>
        </>
      )}

      {!Boolean(success) && (
        <>
          <FormGroup>
            <Label for="email" className="font-weight-bold">
              Enter your email address and we will send you a link to reset your password.
            </Label>

            <Input
              autoComplete="username"
              autoFocus
              disabled={isSubmitting}
              id="email"
              name="email"
              onChange={handleInputChange}
              tabIndex={1}
              type="email"
              value={values.email}
            />
          </FormGroup>

          <Button
            block
            loading={isSubmitting}
            color="primary"
            className="font-weight-bold"
            type="submit"
            onClick={handleSubmit}
            tabIndex={2}
          >
            Send password reset email
          </Button>

          <Button block color="link" className="font-weight-bold" tag={Link} to="/login">
            Return to sign in
          </Button>
        </>
      )}
    </Form>
  );
};
