import React from 'react';
import { Label, FormGroup, Col } from 'reactstrap';
import { sentenceCase } from 'change-case';

type NumberSelectProps = {
  onChange: (e: React.ChangeEvent<any>) => void;
  field: string;
  label?: string;
  value: any;
  options: number[];
};

export const NumberSelect = (props: NumberSelectProps): JSX.Element => {
  const { value, field, label, onChange, options } = props;
  let fieldName = field;
  if (field.split('.').length) {
    fieldName = field.split('.')[field.split('.').length - 1];
  }

  return (
    <FormGroup row>
      <Label xs={12} for="status" className="font-weight-bold">
        {label ? label : sentenceCase(fieldName)}
      </Label>
      <Col xs={6}>
        <select className="form-control" id={field} name={field} onChange={onChange} value={value}>
          {options.map(number => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
      </Col>
    </FormGroup>
  );
};