import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app/store';
import { RootState } from 'app/store/reducer';
import { IShard, IShardLastReset } from 'api';

type ShardState = {
  activeKey: undefined | string;
  byId: Record<string, IShard>;
  ids: string[];
};

const initialState: ShardState = {
  activeKey: undefined,
  byId: {},
  ids: [],
};

const shard = createSlice({
  name: 'shard',
  initialState,
  reducers: {
    setActiveShardKey: (state: ShardState, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    clearActiveShardKey: (state: ShardState) => {
      state.activeKey = undefined;
    },
    fetchShardsSuccess: (state: ShardState, action: PayloadAction<IShard[]>) => {
      const { payload: shards } = action;

      shards.forEach((shard) => {
        state.byId[shard.id] = shard;
      });

      state.ids = shards.map((shard) => shard.id);
    },
    fetchLastShardResetSuccess: (state: ShardState, action: PayloadAction<IShardLastReset>) => {
      const { payload: lastReset } = action;

      state.byId[lastReset.id].dataResetTracking = lastReset.dataResetTracking;
    },
  },
});

export const shardReducer = shard.reducer;
export const { clearActiveShardKey, fetchShardsSuccess } = shard.actions;

export function getActiveShardKey(state: RootState): string | undefined {
  return state.shard.activeKey;
}

export function getActiveShard(state: RootState): IShard | undefined {
  const { shard } = state;
  const { activeKey, byId, ids } = shard;

  if (activeKey) {
    return ids.map((id) => byId[id]).filter((shard) => shard.key === activeKey)[0];
  }

  return undefined;
}

export function getShards(state: RootState): IShard[] {
  return state.shard.ids.map((id) => state.shard.byId[id]);
}

export function fetchShards(): AppThunk<void> {
  return async function fetchShardsThunk(dispatch, _getState, api) {
    try {
      const shards = await api.shard.list();
      dispatch(fetchShardsSuccess(shards));
      return Promise.resolve(shards);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function setActiveShardKey(shardKey: string): AppThunk<void> {
  return function setActiveShardKeyThunk(dispatch, _getState, api) {
    api.shardKey = shardKey;
    dispatch(shard.actions.setActiveShardKey(shardKey));
  };
}

export function fetchShardLastReset(shardKey: string): AppThunk<void> {
  return async function fetchShardLastResetThunk(dispatch, _getState, api) {
    try {
      const lastReset = await api.shard.shardResetStatus(shardKey);
      dispatch(shard.actions.fetchLastShardResetSuccess({ ...lastReset, key: shardKey }));
      return Promise.resolve(lastReset);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function resetShard(shardKey: string): AppThunk<void> {
  return async function resetShardThunk(dispatch, _getState, api) {
    try {
      const reset = await api.shard.shardReset(shardKey);
      return Promise.resolve(reset);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
