import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

type DropProps = {
  maxSize?: number;
  onSubmit: (args: FormData) => Promise<any>;
};

export const Drop = (props: DropProps): JSX.Element => {
  const [isUploading, setIsUploading] = useState(false);

  const { onSubmit, maxSize } = props;

  const onDrop = useCallback(
    async acceptedFiles => {
      const formData = new FormData();

      for (const file of acceptedFiles) {
        formData.append('file', file);
      }

      setIsUploading(true);

      try {
        await onSubmit(formData);
        setIsUploading(false);
      } catch (err) {
        setIsUploading(false);
      }
    },
    [onSubmit]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize
  });

  return (
    <div
      style={{ cursor: 'pointer' }}
      {...getRootProps({
        className: classNames({
          btn: true,
          'btn-primary': true,
          'btn-block': true,
          disabled: isUploading
        })
      })}
    >
      <input {...getInputProps()} />
      {isUploading ? 'Uploading ...' : 'Upload Image'}
    </div>
  );
};

export default Drop;
