import { withFormik } from 'formik';

import { ISettings, PageProps } from 'types';
import { getErrorMessage } from 'utilities';

import { SettingsForm } from '..';

export const SettingsFormWrapper = withFormik<ISettings & PageProps<ISettings>, any>({
  // Transform outer props into form values
  mapPropsToValues: ({ status, features, passwordRequirements, cache, payments, creditCheck }): ISettings => {
    return {
      status,
      features,
      passwordRequirements,
      cache,
      payments,
      creditCheck
    };
  },

  handleSubmit: async (props, formik) => {
    const { name, endpoint, values, ...rest } = props;
    const {
      props: { onSubmit, onSuccessToast, onFailureToast }
    } = formik;

    if (onSubmit) {
      try {
        await onSubmit(rest);
        onSuccessToast && onSuccessToast();
      } catch (error) {
        const err = error as any;
        const msg = getErrorMessage(error) || err.message;
        onFailureToast && onFailureToast(msg);
      }
    }
  }
})(SettingsForm);
