import React from 'react';
import classNames from 'classnames';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Input } from 'reactstrap';
import { range } from 'lodash';

import { ISettingsFeatures, ISettingsPayments, PaymentMethods } from 'types';

import { Checkbox, NumberSelect } from '../../components';

const PAYMENT_METHODS = [
  {
    type: 'Credit Card',
    value: PaymentMethods.CreditCard
  },
  {
    type: 'ACH',
    value: PaymentMethods.Ach
  }
];

export type PaymentsCardProps = {
  features: ISettingsFeatures;
  handleChange: any;
  payments: ISettingsPayments;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const PaymentsCard = (props: PaymentsCardProps) => {
  const {
    features,
    handleChange,
    payments,
    setFieldValue,
  } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Payments</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label xs={12} for="payments.statementDescriptor" className="font-weight-bold">
            Statement Descriptor
          </Label>

          <Col xs={6}>
            <Input
              name="payments.statementDescriptor"
              type="text"
              maxLength={22}
              value={payments.statementDescriptor ? payments.statementDescriptor : ''}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={12} for="payments.defaultNetPaymentTerms" className="font-weight-bold">
            Default Net Payment Terms
          </Label>

          <Col xs={6}>
            <select
              className="form-control"
              id="payments.defaultNetPaymentTerms"
              name="payments.defaultNetPaymentTerms"
              onChange={handleChange}
              value={payments.defaultNetPaymentTerms}
            >
              <option value="5">Net 7</option>
              <option value="0">Net 10</option>
              <option value="1">Net 15</option>
              <option value="6">Net 20</option>
              <option value="2">Net 30</option>
              <option value="3">Net 45</option>
            </select>
          </Col>
        </FormGroup>

        <NumberSelect
          value={payments.portfolioDaysToMature}
          field="payments.portfolioDaysToMature"
          onChange={handleChange}
          options={range(0, 91)}
        />

        <NumberSelect
          value={payments.daysInAdvanceToRemindAutoPayment}
          field="payments.daysInAdvanceToRemindAutoPayment"
          onChange={handleChange}
          options={range(0, 21)}
        />

        <FormGroup row>
          <Label xs={12} for="status" className="font-weight-bold">
            Payment Methods (click to toggle)
          </Label>

          <Col className="d-flex" xs={12}>
            {PAYMENT_METHODS.map(({ type, value }) => (
              <button
                key={type}
                onClick={e => {
                  e.preventDefault();
                  const values = payments.paymentMethods;
                  if (values.includes(value)) {
                    const itemIndex = values.findIndex(v => v === value);
                    values.splice(itemIndex, 1);
                    setFieldValue('payments.paymentMethods', values);
                  } else {
                    values.push(value);
                    setFieldValue('payments.paymentMethods', values);
                  }
                }}
                style={{ outline: 'none' }}
                className={classNames('badge-pill py-2 px-3 m-1', {
                  'badge-primary': payments.paymentMethods.includes(value),
                  'badge-light': !payments.paymentMethods.includes(value)
                })}
              >
                <strong>{type}</strong>
              </button>
            ))}
          </Col>
        </FormGroup>

        <Checkbox
          field="payments.optIntoAutoPaymentByDefault"
          checked={payments.optIntoAutoPaymentByDefault}
          setFieldValue={setFieldValue}
        />
        <Checkbox field="features.enablePlaid" checked={features.enablePlaid} setFieldValue={setFieldValue} />
      </CardBody>
    </Card>
  );
};
