export const State = {
  1: {
    abbr: 'AL',
    name: 'Alabama'
  },

  2: {
    abbr: 'AK',
    name: 'Alaska'
  },

  3: {
    abbr: 'AZ',
    name: 'Arizona'
  },

  4: {
    abbr: 'AR',
    name: 'Arkansas'
  },

  5: {
    abbr: 'CA',
    name: 'California'
  },

  6: {
    abbr: 'CO',
    name: 'Colorado'
  },

  7: {
    abbr: 'CT',
    name: 'Connecticut'
  },

  8: {
    abbr: 'DE',
    name: 'Delaware'
  },

  9: {
    abbr: 'DC',
    name: 'District of Columbia'
  },

  10: {
    abbr: 'FL',
    name: 'Florida'
  },

  11: {
    abbr: 'GA',
    name: 'Georgia'
  },

  12: {
    abbr: 'HI',
    name: 'Hawaii'
  },

  13: {
    abbr: 'ID',
    name: 'Idaho'
  },

  14: {
    abbr: 'IL',
    name: 'Illinois'
  },

  15: {
    abbr: 'IN',
    name: 'Indiana'
  },

  16: {
    abbr: 'IA',
    name: 'Iowa'
  },

  17: {
    abbr: 'KS',
    name: 'Kansas'
  },

  18: {
    abbr: 'KY',
    name: 'Kentucky'
  },

  19: {
    abbr: 'LA',
    name: 'Louisiana'
  },

  20: {
    abbr: 'ME',
    name: 'Maine'
  },

  21: {
    abbr: 'MD',
    name: 'Maryland'
  },

  22: {
    abbr: 'MA',
    name: 'Massachusetts'
  },

  23: {
    abbr: 'MI',
    name: 'Michigan'
  },

  24: {
    abbr: 'MN',
    name: 'Minnesota'
  },

  25: {
    abbr: 'MS',
    name: 'Mississippi'
  },

  26: {
    abbr: 'MO',
    name: 'Missouri'
  },

  27: {
    abbr: 'MT',
    name: 'Montana'
  },

  28: {
    abbr: 'NE',
    name: 'Nebraska'
  },

  29: {
    abbr: 'NV',
    name: 'Nevada'
  },

  30: {
    abbr: 'NH',
    name: 'New Hampshire'
  },

  31: {
    abbr: 'NJ',
    name: 'New Jersey'
  },

  32: {
    abbr: 'NM',
    name: 'New Mexico'
  },

  33: {
    abbr: 'NY',
    name: 'New York'
  },

  34: {
    abbr: 'NC',
    name: 'North Carolina'
  },

  35: {
    abbr: 'ND',
    name: 'North Dakota'
  },

  36: {
    abbr: 'OH',
    name: 'Ohio'
  },

  37: {
    abbr: 'OK',
    name: 'Oklahoma'
  },

  38: {
    abbr: 'OR',
    name: 'Oregon'
  },

  39: {
    abbr: 'PA',
    name: 'Pennsylvania'
  },

  40: {
    abbr: 'RI',
    name: 'Rhode Island'
  },

  41: {
    abbr: 'SC',
    name: 'South Carolina'
  },

  42: {
    abbr: 'SD',
    name: 'South Dakota'
  },

  43: {
    abbr: 'TN',
    name: 'Tennessee'
  },

  44: {
    abbr: 'TX',
    name: 'Texas'
  },

  45: {
    abbr: 'UT',
    name: 'Utah'
  },

  46: {
    abbr: 'VT',
    name: 'Vermont'
  },

  47: {
    abbr: 'VA',
    name: 'Virginia'
  },

  48: {
    abbr: 'WA',
    name: 'Washington'
  },

  49: {
    abbr: 'WV',
    name: 'West Virginia'
  },

  50: {
    abbr: 'WI',
    name: 'Wisconsin'
  },

  51: {
    abbr: 'WY',
    name: 'Wyoming'
  }
};