import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Row,
  Col
} from 'reactstrap';
import { faTrash, faPencil } from '@fortawesome/pro-duotone-svg-icons';

import { Button as SubmitButton, Icon } from 'components';
import { useForm } from 'hooks';
import { IAssociatedUtility, IAssociatedUtilityFormValues, UtilityInfoApiProvider } from 'types';

type AssociatedUtilityItemProps = IAssociatedUtility & {
  onDelete: any;
  onSave: any;
};

export const AssociatedUtilityItem = (props: AssociatedUtilityItemProps): JSX.Element => {
  const { id, name, utilityInfoApiId, utilityInfoApiProvider, canDelete, isNew, onDelete, onSave } = props;

  const [associatedUtilityToDelete, setAssociatedUtilityToDelete] = useState<string | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(isNew));

  const initialValues: IAssociatedUtilityFormValues = {
    name,
    utilityInfoApiId: utilityInfoApiId == null ? '' : utilityInfoApiId,
    utilityInfoApiProvider: utilityInfoApiProvider == null ? '' : utilityInfoApiProvider
  };

  function onSubmit(): any {
    return onSave(isNew, id, values);
  }

  const form = useForm<IAssociatedUtilityFormValues>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  function handleDelete(id: string) {
    setAssociatedUtilityToDelete(id);
  }

  function handleSubmitDelete() {
    if (associatedUtilityToDelete) {
      setIsDeleting(true);
      onDelete(associatedUtilityToDelete);
    }
  }

  function isValid() {
    if (Boolean(values.utilityInfoApiProvider)) {
      return (
        values.name && values.name.trim() !== '' && values.utilityInfoApiId && values.utilityInfoApiId.trim() !== ''
      );
    }

    return values.name && values.name.trim() !== '';
  }

  return (
    <Card className="mb-2">
      <CardHeader className="d-flex align-items-center pr-0 py-2">
        <div className="flex-grow-1 text-truncate">{name}</div>

        <ButtonGroup className="ml-auto">
          <Button color="link" onClick={() => setIsEditing(!isEditing)}>
            <Icon icon={faPencil} />
          </Button>

          {canDelete && (
            <Button color="link" onClick={() => handleDelete(id)}>
              <Icon icon={faTrash} />
            </Button>
          )}
        </ButtonGroup>
      </CardHeader>

      <Collapse isOpen={isEditing}>
        <CardBody>
          <Form>
            {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
            {Boolean(success) && <Alert color="success">Associated Utility saved successfully.</Alert>}

            <Row>
              <Col>
                <FormGroup>
                  <Label for={`associatedUtility-name-${id}`} className="font-weight-bold">
                    Name
                  </Label>

                  <Input
                    autoFocus
                    disabled={isSubmitting}
                    id={`associatedUtility-name-${id}`}
                    name="name"
                    onChange={handleInputChange}
                    type="text"
                    value={values.name}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for={`associatedUtility-utilityInfoApiProvider-${id}`} className="font-weight-bold">
                    API Provider
                  </Label>

                  <Input
                    autoFocus
                    disabled={isSubmitting}
                    id={`associatedUtility-utilityInfoApiProvider-${id}`}
                    name="utilityInfoApiProvider"
                    onChange={e => {
                      if (e.target.value === '') {
                        values.utilityInfoApiId = '';
                      }
                      handleInputChange(e);
                    }}
                    type="select"
                    value={values.utilityInfoApiProvider}
                  >
                    <option value="">Disabled</option>
                    <option value={UtilityInfoApiProvider.UtilityAPI}>Utility API</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for={`associatedUtility-utilityInfoApiId-${id}`} className="font-weight-bold">
                    API Provider ID{' '}
                    {Boolean(values.utilityInfoApiProvider) && Boolean(values.utilityInfoApiId.trim() === '') && (
                      <span className="text-danger">(Required)</span>
                    )}
                  </Label>

                  <Input
                    autoFocus
                    disabled={
                      isSubmitting || values.utilityInfoApiProvider === '' || values.utilityInfoApiProvider == null
                    }
                    id={`associatedUtility-utilityInfoApiId-${id}`}
                    name="utilityInfoApiId"
                    onChange={handleInputChange}
                    type="text"
                    value={values.utilityInfoApiId}
                  />
                </FormGroup>
              </Col>
            </Row>

            <SubmitButton
              block
              disabled={!isValid()}
              loading={isSubmitting}
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </SubmitButton>
          </Form>
        </CardBody>
      </Collapse>

      <Modal isOpen={Boolean(associatedUtilityToDelete)}>
        <ModalHeader>Are you sure?</ModalHeader>

        <ModalBody>This action cannot be undone. Would you like to continue?</ModalBody>

        <ModalFooter>
          <Button color="link" disabled={isDeleting} onClick={() => setAssociatedUtilityToDelete(undefined)}>
            Cancel
          </Button>

          <Button color="danger" disabled={isDeleting} onClick={handleSubmitDelete}>
            {isDeleting ? 'Deleting...' : 'Delete Associated Utility'}
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
}
