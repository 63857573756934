import { Api } from 'api';
import { IResidentialHomepage, PageKey, IFaqItem, IPageBlock } from 'types';
import { BaseService } from './BaseService';

export class ResidentialService extends BaseService {
  constructor(api: Api) {
    super(api, '/shards/residential');
  }

  public fetch() {
    return this.get<any>();
  }

  public updatePage(pageKey: PageKey, values: Partial<IPageBlock>) {
    return this.patch<void>(this.baseUrl, {
      content: {
        [pageKey]: values,
      },
    });
  }

  public updateHomePage(values: Partial<IResidentialHomepage>) {
    return this.patch<void>(this.baseUrl, {
      content: {
        homepage: values,
      },
    });
  }

  public updateHomePageForm(values: Partial<IPageBlock>) {
    return this.patch<void>(this.baseUrl, {
      content: {
        homepage: {
          form: values,
        },
      },
    });
  }

  public updateHomePageBlocks(blocks: IPageBlock[]) {
    return this.patch<void>(this.baseUrl, {
      content: {
        homepage: {
          blocks: blocks.map(({ body, title }) => ({
            body,
            title,
          })),
        },
      },
    });
  }

  public updateFaqs(faqs: IFaqItem[]) {
    return this.patch<void>(this.baseUrl, {
      faqs: {
        items: faqs.map(({ content, title }) => ({
          content,
          title,
        })),
      },
    });
  }
}
