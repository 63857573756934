import React from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'components';

const Success = styled.span`
  color: #42A262;
  margin-right: 0.5rem;
`;

export const LastResetBy = ({ dataResetTracking }: { dataResetTracking: string | undefined }) => {
  if (dataResetTracking) {
    const [name, dateTime] = dataResetTracking.split(';');

    const getDateTime = () => {
      const date = new Date(dateTime);
      // 08/24/2023 at 9:14 AM MT
      return `${date.toLocaleDateString(undefined, { month: '2-digit', day: '2-digit', year: 'numeric' })} at ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`
    }

    const updatedRecently = () => {
      const now: any = new Date();
      const then: any = new Date(dateTime);

      const diff = Math.abs(now - then) / 1000
      return diff <= 60
    }

    if (updatedRecently()) {
      return (
        <div>
          <Success><Icon icon={faCheck} /></Success>
          Reset by {name} just now
        </div>
      )
    } else {
      return (
        <>
          <div>Last reset by {name}</div>
          <div>on {getDateTime()}</div>
        </>
      )
    }
  }

  return null
}
