import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';
import { ISettings, Route } from 'types';
import { onSuccessToast, onFailureToast } from 'utilities';

import { update } from '../effects';
import { SettingsFormWrapper } from './components';

export const Settings = (props: Route) => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.SETTINGS)();
  const dispatch = useDispatch<AppDispatch>();
  const useToastsHook = useToasts();

  const onSubmit = async (values: ISettings) => await dispatch(update(CONFIGURATION_ENDPOINTS.SETTINGS, values));

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <SettingsFormWrapper
          values={data}
          endpoint={CONFIGURATION_ENDPOINTS.SETTINGS}
          name={props.name}
          onSubmit={onSubmit}
          onSuccessToast={onSuccessToast.bind(null, useToastsHook)}
          onFailureToast={onFailureToast.bind(null, useToastsHook)}
          {...data}
        />
      )}
    </ContentWrapper>
  );
};
