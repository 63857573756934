import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { StatsWrapper, Stat } from './AboutUs.styles';
import { useToasts } from 'react-toast-notifications';

export const AboutUs = ({ data, submitUpdate, commonQuestionsUpdate, setAboutUsUpdate }: any) => {
  const { addToast } = useToasts();
  const [toggleOn, setToggleOn] = useState(data?.aboutUs);

  useEffect(() => {
    setToggleOn(data?.aboutUs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = () => {
    setToggleOn(!toggleOn);
    setAboutUsUpdate(!toggleOn);
    let updatedValues;
    if (commonQuestionsUpdate) {
      updatedValues = {
        ...data,
        commonQuestions: commonQuestionsUpdate,
        aboutUs: !data.aboutUs,
      };
    } else {
      updatedValues = {
        ...data,
        aboutUs: !data.aboutUs,
      };
    }
    submitUpdate(updatedValues).then(() => {
      addToast('About Us preferences updated successfully.', { appearance: 'success' });
    });
  };

  return (
    <Card>
      <CardHeader className="font-weight-bold">
        <div className="d-flex">
          <div className="mr-1" style={{ marginTop: '2px' }}>
            <ToggleSwitch
              toggleOn={toggleOn}
              name="aboutUs"
              id="aboutUsToggle"
              handleChange={handleChange}
              theme="blue"
            />
          </div>
          About Us
        </div>
      </CardHeader>
      <CardBody className="d-grid">
        <StatsWrapper>
          <Stat>
            <p className="label">Number of subscribers</p>
            <p className="value">{data?.aboutUsMetrics?.numberOfSubscribers}</p>
          </Stat>
          <Stat>
            <p className="label">Number of projects</p>
            <p className="value">{data?.aboutUsMetrics?.numberOfProjects}</p>
          </Stat>
          <Stat>
            <p className="label">MW developed & managed</p>
            <p className="value">{data?.aboutUsMetrics?.megaWattsManaged}</p>
          </Stat>
          <Stat>
            <p className="label">Number of utilities served</p>
            <p className="value">{data?.aboutUsMetrics?.utilitiesServed}</p>
          </Stat>
        </StatsWrapper>
      </CardBody>
    </Card>
  );
};
