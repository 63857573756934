import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { getActiveShardKey, getSession } from 'utilities';

import * as Styles from './AppLayout.styles';
import { Navigation } from './components';
import { Header } from 'components/Header';
import Loading from 'components/Loading/Loading';

const ToastContainer = (props: any) => <DefaultToastContainer style={{ zIndex: 9999 }} {...props} />;

export const AppLayout = (): JSX.Element => {
  const activeShardKey = useSelector(getActiveShardKey);

  const { isAuthenticated } = useSelector(getSession);

  if (!isAuthenticated) {
    return <Loading />;
  }

  if (activeShardKey) {
    return (
      <>
        <div style={{ position: 'relative', zIndex: 100 }}>
          <Header />
        </div>
        <Styles.App>
          <Navigation />
          <Styles.Content>
            <ToastProvider autoDismiss components={{ ToastContainer }}>
              <Container fluid>
                <Row>
                  <Col>
                    <Outlet />
                  </Col>
                </Row>
              </Container>
            </ToastProvider>
          </Styles.Content>
        </Styles.App>
      </>
    );
  }

  return <Navigate to="/shards" />;
};
