import React from 'react';

import { ContentWrapper } from 'components';
import { useResidentialContent } from 'hooks';

import { HomePageFormEditorForm } from './components';

export const HomePageFormEditor = (): JSX.Element => {
  const { data, error, loading } = useResidentialContent();
  const { content } = data;

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(content) && <HomePageFormEditorForm content={content.homepage.form} />}
    </ContentWrapper>
  );
};
