import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  color: #333;
  border-bottom: 2px solid #feffff !important;
  background-color: #feffff;
  box-shadow: 0px 2px 4px 0px #0000001f;

  &.login {
    background-color: #fff2e5;
    border: 1px solid #ff7900 !important;
  }

  &.demo {
    border-bottom: 2px solid #0072b7 !important;
    background-color: #f5f9fc;
  }

  &.seed {
    border-bottom: 2px solid #ff7900 !important;
    background-color: #fff2e5;
  }

  &.sandbox {
    border-bottom: 2px solid #42a262 !important;
    background-color: #ecf6ef;
  }

  .env-badge {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(calc(-50% + 6.5rem), -50%);
    padding: 0.25rem 1rem;
    border-radius: 4px;
    &.sandbox {
      background-color: #ecf6ef;
      border: 1px solid #42a262;
    }
    &.demo {
      background-color: #f5f9fc;
      border: 1px solid #0072b7;
    }
    &.seed {
      background-color: #fff2e5;
      border: 1px solid #ff7900;
    }
  }

  .menu-container {
    cursor: pointer;
  }

  .menu {
    position: absolute;
    padding: 14px 16px;
    border-radius: 4px;
    background-color: #f2f5f8;
    right: 1.5rem;
    top: 3.5rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: #333333;

    .signout {
      cursor: pointer;
      display: inline-block;
    }

    a {
      display: block;
      color: #333333;
      text-decoration: none;
    }

    .icon {
      color: #576068;
    }
  }
`;
