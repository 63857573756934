import React from 'react';
import { Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { sentenceCase } from 'change-case';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { SaveButton } from 'components';
import { useForm, IUseForm } from 'hooks';
import { ISecrets, PageProps } from 'types';
import { handleNestedChange, onFailureToast, onSuccessToast } from 'utilities';

import { update } from '../../../effects';

interface Group<A extends keyof ISecrets> {
  topField: A;
  subFields: any;
}

/**
 * semi-complex to type using array indices to correspond with object keys
 */
const groups: Group<keyof ISecrets>[] = [
  {
    topField: 'analytics',
    subFields: {
      globalKey: '',
      shardKey: '',
    },
  },
  {
    topField: 'auth0',
    subFields: {
      organizationId: '',
      clientId: '',
    },
  },
  {
    topField: 'gtm',
    subFields: {
      key: '',
    },
  },
  {
    topField: 'plaid',
    subFields: {
      clientId: '',
      secret: '',
      environment: '',
    },
  },
  {
    topField: 'stripe',
    subFields: {
      publishableKey: '',
      secretKey: '',
      webhook: '',
    },
  },
  {
    topField: 'utilityApi',
    subFields: {
      webhook: '',
    },
  },
  {
    topField: 'monitoring',
    subFields: {
      key: '',
    },
  },
  {
    topField: 'clarity',
    subFields: {
      key: '',
    },
  },
];

export const KeysForm = (
  props: ISecrets & PageProps<ISecrets> & { stripeWebhookUrl: string } & { utilityApiWebhookUrl: string }
) => {
  const { endpoint, ...iSecrets } = props;

  const dispatch = useDispatch<AppDispatch>();
  const useToastsHook = useToasts();
  const onSuccess = onSuccessToast.bind(null, useToastsHook);
  const onFailure = onFailureToast.bind(null, useToastsHook);

  function onSubmit() {
    return dispatch(update(endpoint as CONFIGURATION_ENDPOINTS, values));
  }

  const handleWebhookUrl = (topField: string, key: string, props: any) => {
    let url = '';

    if (topField === 'stripe' && key === 'webhook') {
      url = props.stripeWebhookUrl;
    }

    if (topField === 'utilityApi' && key === 'webhook') {
      url = props.utilityApiWebhookUrl;
    }

    if (url) {
      return <small>{url}</small>;
    }
  };

  const initialValues: ISecrets = iSecrets;

  // TODO: Look into why we can't use the inferred type here
  const form: IUseForm<ISecrets> = useForm<ISecrets>(initialValues, onSubmit, onSuccess, onFailure);
  const { handleSubmit, isSubmitting, values } = form;

  return (
    <>
      {groups.map(({ topField, subFields }) => (
        <Card className="mb-3" key={topField as string}>
          <CardHeader tag="h5">{sentenceCase(topField as string)}</CardHeader>

          <CardBody>
            <Form>
              <Row>
                <Col xs={12}>
                  {Object.keys(subFields).map((key) => (
                    <div key={key}>
                      {topField === 'plaid' && key === 'environment' ? (
                        <FormGroup key={key}>
                          <Label for={key} className="font-weight-bold text-monospace">
                            {key}
                          </Label>
                          <Input
                            type="select"
                            disabled={isSubmitting}
                            id={key}
                            name={key}
                            onChange={(e) =>
                              handleNestedChange({
                                event: e,
                                topField,
                                form,
                              })
                            }
                            value={(values[topField] as any)[key] ? (values[topField] as any)[key] : ''}
                          >
                            <option value="">Select</option>
                            <option value="sandbox">Sandbox</option>
                            <option value="development">Development</option>
                            <option value="production">Production</option>
                          </Input>
                        </FormGroup>
                      ) : (
                        <FormGroup key={key}>
                          <Label for={key} className="font-weight-bold text-monospace">
                            {key}
                          </Label>

                          <Input
                            className="text-monospace"
                            disabled={isSubmitting}
                            id={key}
                            name={key}
                            onChange={(e) =>
                              handleNestedChange({
                                event: e,
                                topField,
                                form,
                              })
                            }
                            type="text"
                            value={(values[topField] as any)[key] ? (values[topField] as any)[key] : ''}
                            // opting for ease of creation here,
                            //   TODO: find a more elaborate way to satisfy the typing.
                          />
                          {handleWebhookUrl(topField, key, props)}
                        </FormGroup>
                      )}
                    </div>
                  ))}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      ))}
      <SaveButton
        disabled={isSubmitting}
        onSubmit={(e: any) => handleSubmit(e)}
        text={isSubmitting ? 'Saving...' : 'Save'}
      />
    </>
  );
};
