import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS, IMAGE_PURPOSE } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';
import { Route, ITheme } from 'types';
import { onSuccessToast, onFailureToast } from 'utilities';

import { update, postFile } from '../effects';
import { ThemeFormWrapper } from './components';

export const Theme = (props: Route) => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.THEME)();

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = async (values: ITheme) => {
    return await dispatch(update(CONFIGURATION_ENDPOINTS.THEME, values));
  };
  const useToastsHook = useToasts();

  const onPostFile = async (formData: FormData, imagePurpose: IMAGE_PURPOSE) => {
    formData.append('imagePurpose', (imagePurpose as unknown) as string);
    return await dispatch(postFile(CONFIGURATION_ENDPOINTS.POST_IMAGE, formData));
  };

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <ThemeFormWrapper
          values={data}
          endpoint={CONFIGURATION_ENDPOINTS.THEME}
          name={props.name}
          onSubmit={onSubmit}
          onPostFile={onPostFile}
          onSuccessToast={onSuccessToast.bind(null, useToastsHook)}
          onFailureToast={onFailureToast.bind(null, useToastsHook)}
          {...data}
        />
      )}
    </ContentWrapper>
  );
};
