import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Loading = () => {
  return (
    <div className="w-100 h-100 text-center">
      <FontAwesomeIcon icon={faSpinner} className="h-100 fa-pulse" size="2x" />
    </div>
  );
};

export default Loading;
