import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';

import { Button as SubmitButton, Icon } from 'components';
import { useForm } from 'hooks';
import { IPageBlock } from 'types';

type BlockItemProps = {
  body: string | undefined;
  id: string;
  onDelete: Function;
  onSave: Function;
  title: string | undefined;
};

export const BlockItem = (props: BlockItemProps): JSX.Element => {
  const { body, id, title, onDelete, onSave } = props;
  const [blockToDelete, setBlockToDelete] = useState<string | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  function onSubmit(): any {
    return onSave(id, values);
  }

  const initialValues: IPageBlock = {
    body,
    title
  };

  const form = useForm<IPageBlock>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  function handleDelete(id: string) {
    setBlockToDelete(id);
  }

  function handleSubmitDelete() {
    setIsDeleting(true);
    onDelete(blockToDelete);
  }

  function isValid() {
    return values.title && values.title.trim() !== '' && values.body && values.body.trim() !== '';
  }

  return (
    <Card className="mb-2">
      <CardHeader className="d-flex align-items-center px-0 py-2">
        <Button color="link" className="handle" style={{ cursor: 'move' }}>
          <Icon icon={faGripVertical} />
        </Button>

        <div className="flex-grow-1 text-truncate">{title}</div>

        <Button className="ml-auto" color="link" onClick={() => handleDelete(id)}>
          <Icon icon={faTrash} />
        </Button>
      </CardHeader>

      <CardBody>
        <Form>
          {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
          {Boolean(success) && <Alert color="success">Block saved successfully.</Alert>}

          <FormGroup>
            <Label for={`block-title-${id}`} className="font-weight-bold">
              Title
            </Label>

            <Input
              autoFocus
              disabled={isSubmitting}
              id={`block-title-${id}`}
              name="title"
              onChange={handleInputChange}
              type="text"
              value={values.title}
            />
          </FormGroup>

          <FormGroup>
            <Label for={`block-body-${id}`} className="font-weight-bold">
              Body
            </Label>

            <Input
              disabled={isSubmitting}
              id={`block-body-${id}`}
              name="body"
              onChange={handleInputChange}
              type="textarea"
              rows="4"
              value={values.body}
            />
          </FormGroup>

          <SubmitButton
            block
            disabled={!isValid()}
            loading={isSubmitting}
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </SubmitButton>
        </Form>
      </CardBody>

      <Modal isOpen={Boolean(blockToDelete)}>
        <ModalHeader>Are you sure?</ModalHeader>

        <ModalBody>This action cannot be undone. Would you like to continue?</ModalBody>

        <ModalFooter>
          <Button color="link" disabled={isDeleting} onClick={() => setBlockToDelete(undefined)}>
            Cancel
          </Button>

          <Button color="danger" disabled={isDeleting} onClick={handleSubmitDelete}>
            {isDeleting ? 'Deleting...' : 'Delete Block'}
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};
