import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IAddUserValues } from 'types';

type AddUserModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
  onSubmit: (values: IAddUserValues) => Promise<void>;
};

const AddUserSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),

  lastName: Yup.string().required('Last name is required'),

  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required')
});

export const AddUserModal = (props: AddUserModalProps): JSX.Element => {
  const { isOpen, onCancel, setIsOpen } = props;

  const initialValues: IAddUserValues = {
    firstName: '',
    lastName: '',
    email: ''
  };

  function onSubmit(values: IAddUserValues) {
    props.onSubmit(values).then(
      response => {
        console.log(response);

        resetForm();
        setIsOpen(false);
      },
      error => {
        setSubmitting(false);
      }
    );
  }

  const formik = useFormik<IAddUserValues>({
    initialValues,
    onSubmit,
    validationSchema: AddUserSchema
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
    touched,
    values
  } = formik;

  return (
    <Modal backdrop="static" isOpen={isOpen}>
      <Form autoComplete="nope">
        <ModalHeader>Add New User</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="firstName">
              First Name <span className="text-danger">*</span>
            </Label>

            <Input
              id="firstName"
              name="firstName"
              type="text"
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.firstName}
            />

            {Boolean(errors.firstName && touched.firstName) && (
              <small className="form-text text-danger">{errors.firstName}</small>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="lastName">
              Last Name <span className="text-danger">*</span>
            </Label>

            <Input
              id="lastName"
              name="lastName"
              type="text"
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.lastName}
            />

            {Boolean(errors.lastName && touched.lastName) && (
              <small className="form-text text-danger">{errors.lastName}</small>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="email">
              Email Address <span className="text-danger">*</span>
            </Label>

            <Input
              id="email"
              name="email"
              type="email"
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.email}
            />

            {Boolean(errors.email && touched.email) && <small className="form-text text-danger">{errors.email}</small>}
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            color="link"
            onClick={e => {
              handleReset(e);
              onCancel();
            }}
          >
            Cancel
          </Button>

          <Button disabled={isSubmitting} color="primary" type="submit" onClick={() => handleSubmit()}>
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
