import { useToasts } from 'react-toast-notifications';

/**
 * send a success toast; if we send a custom message, assume we don't want to auto dismiss.
 * @param addToast
 * @param msg
 */
export const onSuccessToast = (useToast: ReturnType<typeof useToasts>, msg?: string, autoDismiss?: boolean): void => {
  const message = msg ? msg : 'Saved successfully.';
  useToast.removeAllToasts();
  useToast.addToast(message, {
    appearance: 'success',
    autoDismiss: autoDismiss ? autoDismiss : typeof msg === 'undefined'
  });
};

/**
 * send a failure toast; if we send a custom message, assume we don't want to auto dismiss.
 * @param addToast
 * @param msg
 */
export const onFailureToast = (useToast: ReturnType<typeof useToasts>, msg?: string, autoDismiss?: boolean): void => {
  let message = msg ? msg : 'There was an error saving the content. Please try again.';
  useToast.removeAllToasts();
  useToast.addToast(message, {
    appearance: 'error',
    autoDismiss: autoDismiss ? autoDismiss : typeof msg === 'undefined'
  });
};
