import React, { SyntheticEvent } from 'react';
import { Button } from 'reactstrap';

import * as Styles from './SaveButton.styles';

export type SaveButtonProps = {
  disabled: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined | SyntheticEvent<Element, Event>) => void;
  text: string;
}

export const SaveButton = (props: SaveButtonProps): JSX.Element => {
  const { disabled, onSubmit, text } = props;

  return (
    <Styles.SaveButtonFixed>
      <Button
        className="mt-0"
        block
        disabled={disabled}
        color="primary"
        type="submit"
        onClick={(e) => onSubmit(e)}
      >
        {text}
      </Button>
    </Styles.SaveButtonFixed>
  );
};
