import { Api } from 'api/Api';

export class BaseService {
  protected api: Api;
  protected baseUrl: string;

  constructor(api: Api, baseUrl: string) {
    this.api = api;
    this.baseUrl = baseUrl;
  }

  public get<T>(uri?: string, shardKey?: string): Promise<T> {
    return this.api.request<T>({
      method: 'GET',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': shardKey || this.api.shardKey,
      },
    });
  }

  public head<T>(uri: string) {
    this.api.request<T>({
      method: 'HEAD',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
      },
    });
  }

  public delete<T>(uri: string, shardKey?: string) {
    return this.api.request<T>({
      method: 'DELETE',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': shardKey ? shardKey : this.api.shardKey,
      },
    });
  }

  public put<T>(uri: string, body?: any) {
    return this.api.request<T>({
      method: 'PUT',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
      },
      body: JSON.stringify(body),
    });
  }

  public patch<T>(uri: string, body?: any) {
    return this.api.request<T>({
      method: 'PATCH',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
      },
      body: JSON.stringify(body),
    });
  }

  public post<T>(uri: string, body?: any) {
    return this.api.request<T>({
      method: 'POST',
      uri: uri ? uri : this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
      },
      body: JSON.stringify(body),
    });
  }
}
