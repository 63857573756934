import { Action, configureStore } from '@reduxjs/toolkit';
import { throttle, get } from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Api } from 'api';

import { RootState, reducer } from './reducer';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();
const session = get(persistedState, 'session');
const activeShardKey = get(persistedState, 'shard.activeKey');
const api = new Api(session, activeShardKey);

declare global {
  interface Window {
    api: Api;
  }
}

window.api = api;

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: api
      }
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: persistedState || {}
});

store.subscribe(
  throttle(() => {
    const { session, shard } = store.getState();

    saveState({
      session,
      shard
    });
  }, 1000)
);

window.addEventListener('storage', event => {
  const { newValue } = event;

  if (newValue) {
    saveState(JSON.parse(newValue));
  }
});

export type AppDispatch = ThunkDispatch<RootState, Api, Action<string>>;
export type AppThunk<T> = ThunkAction<T, RootState, Api, Action<string>>;

export { api };
