import styled from 'styled-components';

export const CommonQuestionsWrapper = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export const Question = styled.div`
  border-bottom: 1px solid #cad0d6;
  cursor: grab;
  padding: 0.5rem 1rem;

  .dragging {
    curosr: grabbing !important;
    cursor: -webkit-grabbing !important ;
    cursor: -moz-grabbing !important;
  }

  .drag-icon {
    color: #99a1a8;
    height: 0.75rem;
  }

  input[type='checkbox'] {
    margin: 3px 8px 0 8px;
    cursor: pointer !important;
  }

  .dual-billed-pill {
    border: 1px solid #0072b7;
    background-color: #f5f9fc;
    border-radius: 2px;
    font-weight: 900;
    font-size: 9px;
    color: #333333;
    padding: 2px 4px;
    margin-left: 8px;
  }

  .expand-icon {
    color: #0072b7;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const Answer = styled.div`
  margin-left: 2.55rem;
`;
