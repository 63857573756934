import React from 'react';
import { Form, FormGroup, Label, Input, Alert, Card, CardHeader, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { Button } from 'components';
import { useForm } from 'hooks';
import { IPageBlock, PageKey } from 'types';

import { updatePage } from '../../../effects';

type PageEditorProps = {
  content: IPageBlock;
  name: string;
  pageKey: PageKey;
};

export const PageEditor = (props: PageEditorProps): JSX.Element => {
  const { content, name, pageKey } = props;

  const dispatch = useDispatch<AppDispatch>();

  function onSubmit(): any {
    return dispatch(updatePage(pageKey, values));
  }

  const initialValues: IPageBlock = {
    body: content.body || undefined,
    title: content.title || undefined
  };

  const form = useForm<IPageBlock>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  return (
    <Card>
      <CardHeader tag="h5">{name}</CardHeader>

      <CardBody>
        <Form>
          {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
          {Boolean(success) && <Alert color="success">Page saved successfully.</Alert>}

          <FormGroup>
            <Label for="title" className="font-weight-bold">
              Title
            </Label>

            <Input
              disabled={isSubmitting}
              id="title"
              name="title"
              onChange={handleInputChange}
              type="text"
              value={values.title}
            />
          </FormGroup>

          <FormGroup>
            <Label for="body" className="font-weight-bold">
              Body
            </Label>

            <Input
              disabled={isSubmitting}
              id="body"
              name="body"
              onChange={handleInputChange}
              type="textarea"
              rows="8"
              value={values.body}
            />
          </FormGroup>

          <Button block loading={isSubmitting} color="primary" type="submit" onClick={handleSubmit}>
            Save
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
