import React from 'react';
import { Card, CardHeader, CardBody, Col, Row, Alert } from 'reactstrap';
import { sentenceCase } from 'change-case';
import classNames from 'classnames';
import { FormikProps } from 'formik';

import { PercentRange, PercentRow } from 'components';
import { IAutoAllocation } from 'types';

export type PremisePreferencesCardProps = {
  preferenceWeights: IAutoAllocation['preferenceWeights'];
  handleChange: FormikProps<IAutoAllocation>['handleChange'];
};

export const PremisePreferencesCard = (props: PremisePreferencesCardProps): JSX.Element => {
  const { handleChange, preferenceWeights } = props;

  const premiseTotal = preferenceWeights.premises.adjacencyCount + preferenceWeights.premises.projectYield;
  const premiseRemaining = 100 - premiseTotal;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Premise Preferences</CardHeader>

      <CardBody>
        <Alert color="info">These values must add up to 100.</Alert>

        <PercentRow text={sentenceCase('adjacencyCount')} value={preferenceWeights.premises.adjacencyCount}>
          <PercentRange
            max={preferenceWeights.premises.adjacencyCount + premiseRemaining}
            name="preferenceWeights.premises.adjacencyCount"
            onChange={handleChange}
            value={preferenceWeights.premises.adjacencyCount}
            width={(100 * (preferenceWeights.premises.adjacencyCount + premiseRemaining)) / 100}
          />
        </PercentRow>

        <PercentRow text={sentenceCase('projectYield')} value={preferenceWeights.premises.projectYield}>
          <PercentRange
            max={preferenceWeights.premises.projectYield + premiseRemaining}
            name="preferenceWeights.premises.projectYield"
            onChange={handleChange}
            value={preferenceWeights.premises.projectYield}
            width={(100 * (preferenceWeights.premises.projectYield + premiseRemaining)) / 100}
          />
        </PercentRow>

        <Row>
          <Col xs="3" className="font-weight-bold">
            Total
          </Col>

          <Col
            xs="2"
            className={classNames({
              'text-right': true,
              'font-weight-bold': true,
              'text-success': premiseTotal === 100,
              'text-danger': premiseTotal < 100,
            })}
          >
            {premiseTotal}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
