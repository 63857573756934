import { useToasts } from 'react-toast-notifications';
import { api } from 'app/store';

export function useChangePassword() {
  const { addToast } = useToasts();

  function onSubmit() {
    return api.account.changePassword().then(
      () => addToast('Success! Check your email for password reset instructions.', { appearance: 'success' }),
      () => addToast('There was an error requesting a password reset.', { appearance: 'error' })
    );
  }

  return {
    onSubmit,
  };
}
