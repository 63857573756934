import * as Request from 'request-promise';
import { OptionsWithUri, RequestPromise, RequestPromiseOptions } from 'request-promise';
import { RequestAPI, RequiredUriUrl } from 'request';
import { queue, QueueObject } from 'async';

export interface RequestTask extends OptionsWithUri {
  callback: (error: any, data: any) => void;
}

export class RequestClient {
  private concurrency: number = 40;
  private queue: QueueObject<RequestTask>;
  private request: RequestAPI<RequestPromise, RequestPromiseOptions, RequiredUriUrl>;

  constructor() {
    this.request = Request.defaults({
      baseUrl: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      transform: this.preprocess
    });

    this.queue = queue<RequestTask, any>(this.requestGenerator(), this.concurrency);
  }

  preprocess(body: any, response: { headers: any }) {
    return {
      headers: response.headers,
      data: body
    };
  }

  push(task: RequestTask) {
    this.queue.push(task);
  }

  requestGenerator() {
    return (task: RequestTask, done: Function) => {
      const { callback, ...options } = task;

      this.request({ ...options })
        .then(({ headers, data }) => {
          const contentType = headers['content-type'];

          if (contentType === undefined || contentType.startsWith('application/json')) {
            task.callback(undefined, data ? JSON.parse(data) : {});
          } else {
            task.callback(undefined, data);
          }

          done();
        })
        .catch(error => {
          task.callback(error, undefined);
          done();
        });
    };
  }
}
