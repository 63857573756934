import React from 'react';

import { CONFIGURATION_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';

import { ContactForm } from './components';

export const Contact = (props: { name: string }): JSX.Element => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.CONTACT)();

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && <ContactForm values={data} {...data} endpoint={CONFIGURATION_ENDPOINTS.CONTACT} name={props.name} />}
    </ContentWrapper>
  );
};
