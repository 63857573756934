import { Api } from 'api';
import { BaseService } from './BaseService';

export enum ShardStatus {
  Active,
  Pending,
  Inactive,
}

export interface IHost {
  id: string;
  uiUrl: string;
  uiHostname: string;
  apiHostname: string;
  shardProfileId: string;
  key: string;
}

export interface IShard {
  id: string;
  name: string;
  logoUrl: string;
  status: ShardStatus;
  hosts: IHost[];
  key: string;
  canResetDatabase: boolean;
  defaultShardHostId: string;
  hostname: string;
  isDemoShard: boolean;
  isSandboxShard: boolean;
  isSeedShard: boolean;
  sandboxKey: string;
  sandboxOfShardProfileId: string;
  dataResetTracking?: string;
}

export interface IShardLastReset {
  id: string;
  dataResetTracking: string;
  canResetDatabase: boolean;
  key?: string;
}

export class ShardService extends BaseService {
  constructor(api: Api) {
    super(api, '/shards');
  }

  public list() {
    return this.get<any>(this.baseUrl);
  }

  public shardResetStatus(shardKey: string) {
    return this.get<any>('/reset-shard', shardKey);
  }

  public shardReset(shardKey: string) {
    return this.delete<any>('reset-shard', shardKey);
  }
}
