import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { AppDispatch } from 'app/store';
import { IShard } from 'api';
import { Icon } from 'components';
import { setActiveShardKey, getActiveShard } from 'utilities';

import { ActiveShard, ShardListWrapper, Shards, Shard } from './ShardList.styles';

type ShardListProps = {
  className?: string;
  shards: IShard[];
  setToggleOn?: (arg: boolean) => void;
  forLogin?: boolean;
};

export const ShardList = (props: ShardListProps): JSX.Element => {
  const { className, shards, setToggleOn, forLogin = false } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const activeShard = useSelector(getActiveShard);
  const dispatch = useDispatch<AppDispatch>();
  const node = useRef<HTMLDivElement>(null);

  function toggleDropdown() {
    setDropdownOpen((prevState) => !prevState);
  }

  function handleSetShardClick(shardKey: string) {
    if (!forLogin) {
      //@ts-ignore
      setToggleOn(false);
    }
    dispatch(setActiveShardKey(shardKey));
    setDropdownOpen(false);
  }

  function handleOutsideClick(event: MouseEvent) {
    if (node && node.current && node.current.contains(event.target as Node)) {
      return;
    }

    setDropdownOpen(false);
  }

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen]);

  return (
    <ShardListWrapper className={className} ref={node}>
      <ActiveShard
        onClick={toggleDropdown}
        className={classNames({
          open: dropdownOpen,
        })}
      >
        <div className="d-flex">
          <div className="flex-grow-1 text-truncate">{activeShard ? activeShard.name : 'Select a Shard'}</div>
          <div>
            <Icon icon={faCaretDown} />
          </div>
        </div>
      </ActiveShard>

      <Shards
        className={classNames({
          open: dropdownOpen,
          top: className && className.includes('top')
        })}
      >
        {shards
          .filter((shard) => shard.key && !shard?.isSandboxShard)
          .map((shard) => (
            <Shard
              onClick={() => handleSetShardClick(shard.key)}
              className={classNames({
                'text-truncate': true,
                active: activeShard && activeShard.key === shard.key,
              })}
              key={shard.id}
            >
              {shard.name}
            </Shard>
          ))}
      </Shards>
    </ShardListWrapper>
  );
};
