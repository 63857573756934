import React from 'react';
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { Button } from 'components';
import { IResidentialHomepage } from 'types';
import { useForm } from 'hooks';

import { updateHomePage } from '../../../effects';

type HomePageEditorFormProps = {
  content: IResidentialHomepage;
};

export const HomePageEditorForm = (props: HomePageEditorFormProps) => {
  const { content } = props;

  const dispatch = useDispatch<AppDispatch>();

  function onSubmit(): any {
    return dispatch(updateHomePage(values));
  }

  const initialValues: Partial<IResidentialHomepage> = {
    lead: content.lead || undefined,
    media: content.media || undefined,
    mediaPosition: content.mediaPosition || undefined,
    subtitle: content.subtitle || undefined,
    title: content.title || undefined
  };

  const form = useForm<Partial<IResidentialHomepage>>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  return (
    <Form>
      {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
      {Boolean(success) && <Alert color="success">Page saved successfully.</Alert>}

      <FormGroup>
        <Label for="title" className="font-weight-bold">
          Title
        </Label>

        <Input
          disabled={isSubmitting}
          id="title"
          name="title"
          onChange={handleInputChange}
          type="text"
          value={values.title}
        />
      </FormGroup>

      <FormGroup>
        <Label for="subtitle" className="font-weight-bold">
          Subtitle
        </Label>

        <Input
          disabled={isSubmitting}
          id="subtitle"
          name="subtitle"
          onChange={handleInputChange}
          type="text"
          value={values.subtitle}
        />
      </FormGroup>

      <FormGroup>
        <Label for="lead" className="font-weight-bold">
          Lead Paragraph
        </Label>

        <Input
          disabled={isSubmitting}
          id="lead"
          name="lead"
          onChange={handleInputChange}
          type="textarea"
          rows="8"
          value={values.lead}
        />
      </FormGroup>

      <FormGroup>
        <Label for="mediaPostion" className="font-weight-bold">
          Embedded Media Postion
        </Label>

        <Input
          id="mediaPosition"
          name="mediaPosition"
          onChange={handleInputChange}
          type="select"
          value={values.mediaPosition}
        >
          <option value={0}>Top</option>
          <option value={1}>Bottom</option>
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="media" className="font-weight-bold">
          Embedded Media
        </Label>

        <Input
          disabled={isSubmitting}
          id="media"
          name="media"
          onChange={handleInputChange}
          type="textarea"
          rows="8"
          value={values.media}
        />
      </FormGroup>

      <Button block loading={isSubmitting} color="primary" type="submit" onClick={handleSubmit}>
        Save
      </Button>
    </Form>
  );
};
