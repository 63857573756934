import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse
} from 'reactstrap';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { faTrash, faPencil } from '@fortawesome/pro-duotone-svg-icons';

import { Button as SubmitButton, Icon } from 'components';
import { useForm } from 'hooks';
import { IFaqItem } from 'types';

import * as Styles from './FaqItem.styles';

type FaqItemProps = {
  content: string;
  id: string;
  isNew: boolean | undefined;
  onDelete: Function;
  onSave: Function;
  title: string;
};

export const FaqItem = (props: FaqItemProps): JSX.Element => {
  const { content, id, isNew, title, onDelete, onSave } = props;
  const [faqToDelete, setFaqToDelete] = useState<string | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(isNew));

  function onSubmit(): any {
    return onSave(id, values);
  }

  const initialValues: IFaqItem = {
    content,
    title
  };

  const form = useForm<IFaqItem>(initialValues, onSubmit);
  const { error, handleInputChange, handleSubmit, isSubmitting, success, values } = form;

  function handleDelete(id: string) {
    setFaqToDelete(id);
  }

  function handleSubmitDelete() {
    setIsDeleting(true);
    onDelete(faqToDelete);
  }

  function isValid() {
    return values.title.trim() !== '' && values.content.trim() !== '';
  }

  return (
    <Card className="mb-2" tag={Styles.Item}>
      <CardHeader className="d-flex align-items-center px-0 py-2">
        <Button color="link" className="handle" style={{ cursor: 'move' }}>
          <Icon icon={faGripLines} />
        </Button>

        <div className="flex-grow-1 text-truncate">{title}</div>

        <ButtonGroup className="ml-auto">
          <Button color="link" onClick={() => setIsEditing(!isEditing)}>
            <Icon icon={faPencil} />
          </Button>

          <Button color="link" onClick={() => handleDelete(id)}>
            <Icon icon={faTrash} />
          </Button>
        </ButtonGroup>
      </CardHeader>

      <Collapse isOpen={isEditing}>
        <CardBody>
          <Form>
            {Boolean(error) && <Alert color="danger">There was an error saving the content. Please try again.</Alert>}
            {Boolean(success) && <Alert color="success">FAQ saved successfully.</Alert>}

            <FormGroup>
              <Label for={`faq-title-${id}`} className="font-weight-bold">
                Title
              </Label>

              <Input
                autoFocus
                disabled={isSubmitting}
                id={`faq-title-${id}`}
                name="title"
                onChange={handleInputChange}
                type="text"
                value={values.title}
              />
            </FormGroup>

            <FormGroup>
              <Label for={`faq-content-${id}`} className="font-weight-bold">
                Content
              </Label>

              <Input
                disabled={isSubmitting}
                id={`faq-content-${id}`}
                name="content"
                onChange={handleInputChange}
                type="textarea"
                rows="8"
                value={values.content}
              />
            </FormGroup>

            <SubmitButton
              block
              disabled={!isValid()}
              loading={isSubmitting}
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </SubmitButton>
          </Form>
        </CardBody>
      </Collapse>

      <Modal isOpen={Boolean(faqToDelete)}>
        <ModalHeader>Are you sure?</ModalHeader>

        <ModalBody>This action cannot be undone. Would you like to continue?</ModalBody>

        <ModalFooter>
          <Button color="link" disabled={isDeleting} onClick={() => setFaqToDelete(undefined)}>
            Cancel
          </Button>

          <Button color="danger" disabled={isDeleting} onClick={handleSubmitDelete}>
            {isDeleting ? 'Deleting...' : 'Delete FAQ'}
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};
