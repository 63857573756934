import React from 'react';
import { Form } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { SaveButton } from 'components';
import { IContactInfo, PageProps } from 'types';
import { getErrorMessage, onSuccessToast, onFailureToast } from 'utilities';

import { update } from '../../../effects';
import { CompanyCard, EmailAddressCard, RedirectsCard, RemittanceCard, SidebarNavigationCard } from './components';

export const ContactForm = (props: IContactInfo & PageProps<IContactInfo>): JSX.Element => {
  const { name, endpoint, ...contactInfo } = props;

  const dispatch = useDispatch<AppDispatch>();
  const useToastsHook = useToasts();
  const onSuccess = onSuccessToast.bind(null, useToastsHook);
  const onFailure = onFailureToast.bind(null, useToastsHook);

  const formik = useFormik({
    initialValues: contactInfo,
    onSubmit: async values => {
      try {
        await dispatch(update(endpoint as CONFIGURATION_ENDPOINTS, values));
        onSuccess && onSuccess();
      } catch (error) {
        const err = error as any;
        const msg = getErrorMessage(error) || err.message;
        onFailure && onFailure(msg);
      }
    }
  });

  const { handleSubmit, isSubmitting, setFieldValue } = formik;
  const sidebarItems = formik.values.navigation.customerSidebar || [];

  return (
    <Form>
      <CompanyCard
        company={formik.values.contactInfo.company}
        handleChange={formik.handleChange}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
      />

      <RemittanceCard
        remittance={formik.values.contactInfo.remittance}
        handleChange={formik.handleChange}
        isSubmitting={isSubmitting}
      />

      <EmailAddressCard
        email={formik.values.contactInfo.email}
        handleChange={formik.handleChange}
        isSubmitting={isSubmitting}
        remittance={formik.values.contactInfo.remittance}
      />

      <SidebarNavigationCard isSubmitting={isSubmitting} setFieldValue={setFieldValue} sidebarItems={sidebarItems} />

      <RedirectsCard
        handleChange={formik.handleChange}
        isSubmitting={isSubmitting}
        residentialNoProjectsInAreaUrl={formik.values.navigation.residentialNoProjectsInAreaUrl}
      />
      <SaveButton
        disabled={isSubmitting}
        onSubmit={() => handleSubmit()}
        text={isSubmitting ? 'Saving...' : 'Save'}
      />
    </Form>
  );
};
