import React, { useState } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';
import { IEmailTemplates } from 'types';
import { onSuccessToast, onFailureToast } from 'utilities';

import { update, post } from '../../configuration/effects';
import { EmailTemplatesFormWrapper } from './components';

export const EmailTemplates = () => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES)();
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const onSubmit = async (values: IEmailTemplates) => {
    return await dispatch(update(CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES, values));
  };
  const useToastsHook = useToasts();

  const onPost = async (values: IEmailTemplates) => {
    setIsSending(true);
    const fullPath =
    CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES +
      qs.stringify(values, {
        addQueryPrefix: true
      });

    return await dispatch(post(CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES, values, fullPath))
      .then(
        () => onSuccessToast(useToastsHook, 'Test email sent.', true),
        () => onFailureToast(useToastsHook, 'Error sending test email', true)
      )
      .catch(() => onFailureToast(useToastsHook, 'Error sending test email', true))
      .finally(() => setIsSending(false));
  };

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <EmailTemplatesFormWrapper
          values={data}
          endpoint={CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES}
          isSending={isSending}
          name={''}
          onSubmit={onSubmit}
          onPost={onPost}
          onSuccessToast={onSuccessToast.bind(null, useToastsHook)}
          onFailureToast={onFailureToast.bind(null, useToastsHook)}
          {...data}
        />
      )}
    </ContentWrapper>
  );
};
