import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';

import { api } from 'app/store';
import { getActiveShard } from 'utilities';

export function useShardReset() {
  const [loading, setLoading] = useState(false);
  const active = useSelector(getActiveShard);
  const { addToast } = useToasts();

  function onSubmit() {
    setLoading(true);

    return api.shard
      .shardReset(active?.key || '')
      .then(
        () => addToast('Shard was reset successfully.', { appearance: 'success' }),
        () => addToast('There was an error reseting the shard. Please try again.', { appearance: 'error' })
      )
      .finally(() => setLoading(false));
  }

  return {
    loading,
    onSubmit
  }
}
