
import React from 'react';
import {  FormGroup, Label, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { IMAGE_PURPOSE } from 'api';
import { ColorPicker, UploadImageCard } from 'components';


export const ResidentialCard = (props: any): JSX.Element => {
  const { 
    errors, 
    onPostFile,
    setFieldError, 
    setFieldValue, 
    residential
  } = props;
  
  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Residential</CardHeader>

      <CardBody>
        <Row>

          <UploadImageCard
            error={errors?.residential?.residentialBackgroundImageUrl}
            fieldKey="residential.residentialBackgroundImageUrl"
            imageUrl={residential.residentialBackgroundImageUrl}
            label="Background Image (1440x960)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.ResidentialBackgroundImage}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />

          <UploadImageCard
            error={errors?.residential?.residentialPartnerLogoUrl}
            fieldKey="residential.residentialPartnerLogoUrl"
            imageUrl={residential.residentialPartnerLogoUrl}
            label="Partner Logo (400x400)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.ResidentialPartnerLogo}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />          

          <FormGroup tag={Col} key="residential.residentialColor1">
            <Label for="residential.residentialColor1" className="font-weight-bold">
              Color 1
            </Label>

            <ColorPicker
              id="residentialColor1"
              onChange={(color: { hex: any }) => setFieldValue('residential.residentialColor1', color.hex)}
              color={residential.residentialColor1}
            />
          </FormGroup>

          <FormGroup tag={Col} key="residential.residentialColor2">
            <Label for="residential.residentialColor2" className="font-weight-bold">
              Color 2
            </Label>

            <ColorPicker
              id="residentialColor2"
              onChange={(color: { hex: any }) => setFieldValue('residential.residentialColor2', color.hex)}
              color={residential.residentialColor2}
            />
          </FormGroup>

          <FormGroup tag={Col} key="residential.residentialColor3">
            <Label for="residential.residentialColor3" className="font-weight-bold">
              Color 3
            </Label>

            <ColorPicker
              id="residentialColor3"
              onChange={(color: { hex: any }) => setFieldValue('residential.residentialColor3', color.hex)}
              color={residential.residentialColor3}
            />
          </FormGroup>

          <FormGroup tag={Col} key="residential.residentialColor4">
            <Label for="residential.residentialColor4" className="font-weight-bold">
              Color 4
            </Label>

            <ColorPicker
              id="residentialColor4"
              onChange={(color: { hex: any }) => setFieldValue('residential.residentialColor4', color.hex)}
              color={residential.residentialColor4}
            />
          </FormGroup>
        </Row>
      </CardBody>
    </Card>
  );
};