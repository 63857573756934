import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const NavText = styled.span`
  flex-grow: 1;
  line-height: 1.5rem;
  font-weight: 500;
`;

interface NavIconProps {
  active?: boolean;
}

export const NavIcon = styled.div<NavIconProps>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  color: #4a5568;
`;

export const StyledRouterNavLink = styled(RouterNavLink)`
  &.active > ${NavIcon} {
    color: #fff;
  }
`;
