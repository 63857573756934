import styled from 'styled-components';

export const ImagePreview = styled.div`
position: relative;
background-color: #f7fafc;
width: 100%;
padding-bottom: 100%;

> img {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`;