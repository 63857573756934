import React from 'react';
import { useFormik } from 'formik';
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';

import { useUpdateAccount } from 'hooks';
import { ProfileFormValues } from 'types';

interface ProfileFormProps {
  initialValues: ProfileFormValues;
}

export const ProfileForm = (props: ProfileFormProps): JSX.Element => {
  const { initialValues } = props;

  const { onSubmit } = useUpdateAccount(initialValues.id);

  const formik = useFormik<ProfileFormValues>({
    initialValues,
    onSubmit,
  });

  const { handleChange, handleSubmit, isSubmitting, values } = formik;

  return (
    <Form>
      <FormGroup row>
        <Label className="text-right" for="firstName" sm={3}>
          First Name
        </Label>

        <Col>
          <Input
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.firstName}
            type="text"
            name="firstName"
            id="firstName"
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label className="text-right" for="lastName" sm={3}>
          Last Name
        </Label>

        <Col>
          <Input
            disabled={isSubmitting}
            onChange={handleChange}
            type="text"
            name="lastName"
            id="lastName"
            value={values.lastName}
          />
        </Col>
      </FormGroup>

      <FormGroup row className="mb-0">
        <Col sm={{ size: 9, offset: 3 }}>
          <Button disabled={isSubmitting} color="primary" type="submit" onClick={() => handleSubmit()}>
            Update Profile
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};
