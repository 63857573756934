import React from 'react';
import { Button } from 'components';
import { useChangePassword } from 'hooks';

export const RequestPasswordReset = ({ id }: any) => {
  const { onSubmit: submit } = useChangePassword();

  if (!id.includes('auth0')) {
    return (
      <div className="card-content">
        You’ve signed in using a third party account (for example, Google or Facebook), so you must reset your password
        through that account.
      </div>
    );
  } else {
    return (
      <div className="card-content">
        <p>
          Click the button below to receive a Password Reset email. Follow the instructions in the email to reset your
          password.
        </p>
        <Button color="primary" onClick={() => submit()}>
          Send Password Reset Email
        </Button>
      </div>
    );
  }
};
