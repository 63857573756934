import React from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Input } from 'reactstrap';
import { range } from 'lodash';

import { ISettingsFeatures } from 'types';

import { Checkbox, NumberSelect } from '../../components';

type FeaturesCardProps = {
  features: ISettingsFeatures;
  handleChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const FeaturesCard = (props: FeaturesCardProps) => {
  const { features, handleChange, setFieldValue } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Features</CardHeader>
      <CardBody>
        <FormGroup row>
          <Col xs={6}>
            <Checkbox
              field="features.enableStreamlinedSignUpFlows"
              checked={features.enableStreamlinedSignUpFlows}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.enableSignUpFlowDocumentUpload"
              checked={features.enableSignUpFlowDocumentUpload}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.hidePoweredBySunCentral"
              checked={features.hidePoweredBySunCentral}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.disableInvoiceSunCentralDisclaimer"
              checked={features.disableInvoiceSunCentralDisclaimer}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.isResidentialDashboardEnabled"
              checked={features.isResidentialDashboardEnabled}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.isResidentialRegistrationEnabled"
              checked={features.isResidentialRegistrationEnabled}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.isResidentialExpectedSavingsDisabled"
              checked={features.isResidentialExpectedSavingsDisabled}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.remitToPortfolioOwnerIfUnique"
              checked={features.remitToPortfolioOwnerIfUnique}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.enableWelcomeMessage"
              checked={features.enableWelcomeMessage}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.enablePaymentMessage"
              checked={features.enablePaymentMessage}
              setFieldValue={setFieldValue}
            />
            <Checkbox
              field="features.disableSunCentralWebhooks"
              checked={features.disableSunCentralWebhooks}
              setFieldValue={setFieldValue}
            />
            <Label xs={12} for="features.consentDocumentTypes" className="font-weight-bold">
              Enabled Consent Forms
            </Label>
            <Input
              name="features.consentDocumentTypes"
              type="text"
              value={features.consentDocumentTypes}
              onChange={handleChange}
            />
            <Label xs={12} for="features.hubspotChatUrl" className="font-weight-bold">
              Hubspot Tracking Script URL (for Chat in streamlined signup)
            </Label>
            <Input name="features.hubspotChatUrl" type="text" value={features.hubspotChatUrl} onChange={handleChange} />
            <Label xs={12} for="features.residentialMaxAnnualUsage" className="font-weight-bold">
              Estimated Annual Usage Max
            </Label>
            <Input
              name="features.residentialMaxAnnualUsage"
              type="number"
              value={features.residentialMaxAnnualUsage}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>

        <NumberSelect
          value={features.residentialRegistrationSubscriptionTimeToLiveInHours}
          field="features.residentialRegistrationSubscriptionTimeToLiveInHours"
          onChange={props.handleChange}
          options={range(1, 73)}
        />
      </CardBody>
    </Card>
  );
};
