import React from 'react';
import qs from 'qs';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useAuth0Token } from 'hooks';

import {
  AppLayout,
  AuthLayout,
  ForgotPasswordForm,
  LoginForm,
  ResetPasswordForm,
  SelectShard,
  Session,
} from 'components';

import { PATH, routes } from 'routes';
import { AccountSettings, EmailTemplates, Users } from 'pages';
import { Route as RouteClass } from 'types';

export const App = (): JSX.Element => {
  const { isAuthenticated } = useAuth0Token();
  const location = useLocation();

  function parseParams(search: string) {
    const { email, token } = qs.parse(search, { ignoreQueryPrefix: true });

    return {
      email,
      token,
    };
  }

  return (
    <Session>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route
            path={PATH.SHARDS}
            element={
              <RequiredAuth>
                <SelectShard />
              </RequiredAuth>
            }
          />

          <Route
            path={PATH.RESET_PASSWORD}
            element={
              <ResetPasswordForm
                buttonText="Change password"
                email={parseParams(location.search).email}
                token={parseParams(location.search).token}
              />
            }
          />

          <Route
            path={PATH.ACTIVATE_ACCOUNT}
            element={
              <ResetPasswordForm
                buttonText="Activate account"
                email={parseParams(location.search).email}
                token={parseParams(location.search).token}
              />
            }
          />

          <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordForm />} />

          <Route
            path={PATH.LOGIN}
            element={
              <>
                {!isAuthenticated && <LoginForm />}
                {isAuthenticated && <Navigate to={{ pathname: '/' }} />}
              </>
            }
          />
        </Route>

        <Route
          path="/"
          element={
            <RequiredAuth>
              <AppLayout />
            </RequiredAuth>
          }
        >
          <Route path={PATH.ACCOUNT_SETTINGS} element={<AccountSettings />} />

          <Route path={PATH.USERS} element={<Users />} />

          <Route path={PATH.EMAIL_TEMPLATES} element={<EmailTemplates />} />
        </Route>

        <Route
          element={
            <RequiredAuth>
              <AppLayout />
            </RequiredAuth>
          }
        >
          <Route path={PATH.RESIDENTIAL_SIGN_UP + '/*'}>
            {routes.residentialSignup.map((route: RouteClass) => {
              // This is to handle the sub-routes in the Homepage block editor
              if (route.routes && route.routes?.length > 0) {
                return (
                  <Route path={route.path} element={<ComponentFromRoute route={route} key={route.path} />}>
                    {route.routes.map((childRoute: RouteClass) => (
                      <Route
                        path={childRoute.path}
                        element={<ComponentFromRoute route={childRoute} key={childRoute.path} />}
                      />
                    ))}
                  </Route>
                );
              } else {
                return <Route path={route.path} element={<ComponentFromRoute route={route} />} />;
              }
            })}
            <Route path="" element={<Navigate to={PATH.RESIDENTIAL_HOMEPAGE} />} />
          </Route>

          <Route path={PATH.CONTENT + '/*'}>
            {routes.content.map((route: RouteClass) => (
              <Route path={route.path} element={<ComponentFromRoute route={route} key={route.path} />} />
            ))}
          </Route>

          <Route path={PATH.CONFIGURATION + '/*'}>
            {routes.configuration.map((route: RouteClass) => (
              <Route path={route.path} element={<ComponentFromRoute route={route} key={route.path} />} />
            ))}
          </Route>

          <Route path={PATH.ENTITIES + '/*'}>
            {routes.entities.map((route: RouteClass) => (
              <Route path={route.path} element={<ComponentFromRoute route={route} key={route.path} />} />
            ))}
          </Route>

          <Route index element={<Navigate to="configuration/contact-information" />} />
        </Route>
      </Routes>
    </Session>
  );
};

const ComponentFromRoute = ({ route }: any) => {
  const { component: Component, key, name, routes } = route;
  return <Component pageKey={key as string} name={name} routes={routes} />;
};

const RequiredAuth = ({ children }: any) => {
  const AuthComponentConfig = withAuthenticationRequired(() => {
    return <>{children}</>;
  }, {});
  return <AuthComponentConfig />;
};
