import React, { useState } from 'react';
import shortid from 'shortid';
import { Button, CardBody, Card, CardHeader } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { FaqItem } from 'components';
import { IFaqItem } from 'types';

import { updateFaqs } from '../../../effects';

type FaqListProps = {
  faqs: IFaqItem[];
};

interface Faq extends IFaqItem {
  id: string;
  isNew?: boolean;
}

export const FaqList = (props:FaqListProps): JSX.Element => {
  const items = props.faqs.map(faq => ({
    id: shortid.generate(),
    ...faq
  }));

  const [faqs, setFaqs] = useState<Faq[]>(items);
  const dispatch = useDispatch<AppDispatch>();

  function onEnd() {
    return dispatch(updateFaqs(faqs));
  }

  async function onSave(id: string, faq: IFaqItem) {
    const index = faqs.findIndex(faq => faq.id === id);
    const updatedFaqs = [
      ...faqs.slice(0, index),
      {
        ...faqs[index],
        ...faq
      },
      ...faqs.slice(index + 1)
    ];

    await dispatch(updateFaqs(updatedFaqs));
    return setFaqs(updatedFaqs);
  }

  async function onDelete(id: string) {
    const index = faqs.findIndex(faq => faq.id === id);
    const updatedFaqs = faqs.filter((_faq, i) => i !== index);

    await dispatch(updateFaqs(updatedFaqs));
    return setFaqs(updatedFaqs);
  }

  function handleAdd() {
    const updatedFaqs = [
      ...faqs,
      {
        content: '',
        id: shortid.generate(),
        isNew: true,
        title: ''
      }
    ];

    setFaqs(updatedFaqs);
  }

  return (
    <Card>
      <CardHeader tag="h5">FAQs</CardHeader>

      <CardBody>
        <ReactSortable handle=".handle" onEnd={onEnd} animation={250} list={faqs} setList={setFaqs}>
          {faqs.map(faq => (
            <FaqItem
              content={faq.content}
              title={faq.title}
              id={faq.id}
              isNew={faq.isNew}
              key={faq.id}
              onDelete={onDelete}
              onSave={onSave}
            />
          ))}
        </ReactSortable>

        <div className="text-center">
          <Button color="link" className="text-bold" onClick={handleAdd}>
            Add New FAQ
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
