import { 
  HomePageBlocks,
  HomePageEditor,
  HomePageFormEditor,
  ResidentialFaqs,
  ResidentialHomePage,
  ResidentialPage 
} from 'pages';
import { Route } from 'types';

export const baseRoute = 'residential-sign-up';
export const addUtilityRoute = 'add-utility';
export const confirmUtilityRoute = 'confirm-utility';
export const connectUtilityRoute = 'connect-utility';
export const creditCheckRoute = 'credit-check';
export const faqsRoute = 'faqs';
export const homepageRoute = 'homepage';
export const homepageLinkRoute = 'homepage/content';
export const homepageContentRoute = 'content';
export const homepageBlocksRoute = 'blocks';
export const homepageFormRoute = 'form';
export const inquiryFailureRoute = 'inquiry-failure';
export const inquiryRoute = 'inquiry';
export const inquirySuccessRoute = 'inquiry-success';
export const plansRoute = 'plans';
export const reviewRoute = 'review';
export const addPaymentRoute = 'add-payment';
export const finalizeRoute = 'finalize';
export const setPasswordRoute = 'set-password';
export const verifyAccountRoute = 'verify-account';

export const routes: Route[] = [
  {
    component: ResidentialHomePage,
    key: 'homepage',
    name: 'Homepage',
    path: homepageRoute,
    routes: [
      {
        component: HomePageEditor,
        exact: true,
        key: 'content',
        name: 'Content',
        path: homepageContentRoute 
      },

      {
        component: HomePageBlocks,
        exact: true,
        key: 'blocks',
        name: 'Blocks',
        path: homepageBlocksRoute
      },

      {
        component: HomePageFormEditor,
        exact: true,
        key: 'form',
        name: 'Form',
        path: homepageFormRoute
      }
    ]
  },

  {
    component: ResidentialPage,
    key: 'inquiry',
    name: 'Initial Inquiry',
    path: inquiryRoute
  },

  {
    component: ResidentialPage,
    key: 'inquiryFailure',
    name: 'Initial Inquiry (Failure)',
    path: inquiryFailureRoute
  },

  {
    component: ResidentialPage,
    key: 'inquirySuccess',
    name: 'Initial Inquiry (Success)',
    path: inquirySuccessRoute
  },

  {
    component: ResidentialPage,
    key: 'verifyAccount',
    name: 'Verify Account',
    path: verifyAccountRoute
  },

  {
    component: ResidentialPage,
    key: 'setPassword',
    name: 'Set Password',
    path: setPasswordRoute
  },

  {
    component: ResidentialPage,
    key: 'connectUtility',
    name: 'Connect Utility',
    path: connectUtilityRoute
  },

  {
    component: ResidentialPage,
    key: 'confirmUtility',
    name: 'Confirm Utility',
    path: confirmUtilityRoute
  },

  {
    component: ResidentialPage,
    key: 'addUtility',
    name: 'Add Utility',
    path: addUtilityRoute
  },

  {
    component: ResidentialPage,
    key: 'plans',
    name: 'Plans',
    path: plansRoute
  },

  {
    component: ResidentialPage,
    key: 'review',
    name: 'Review',
    path: reviewRoute
  },

  {
    component: ResidentialPage,
    key: 'addPayment',
    name: 'Add Payment',
    path: addPaymentRoute
  },

  {
    component: ResidentialPage,
    key: 'finalize',
    name: 'Finalize',
    path: finalizeRoute
  },

  {
    component: ResidentialPage,
    key: 'creditCheck',
    name: 'Credit Check',
    path: creditCheckRoute
  },

  {
    component: ResidentialFaqs,
    key: 'faqs',
    name: 'FAQs',
    path: faqsRoute
  }
];
