import React from 'react';

export type PercentRangeProps = {
  onChange: any;
  max?: number;
  min?: number;
  name: string;
  step?: number;
  value: number;
  width?: number;
}

export const PercentRange = (props: PercentRangeProps): JSX.Element => {
  const { name, onChange, value } = props;
  const max = typeof props.max !== 'undefined' ? props.max : 100;
  const min = typeof props.min !== 'undefined' ? props.min : 0;
  const step = typeof props.step !== 'undefined' ? props.step : 1;
  const width = typeof props.width !== 'undefined' ? props.width : 100;

  return (
    <input
      min={min}
      max={max}
      step={step}
      className="custom-range"
      type="range"
      name={name}
      onChange={onChange}
      value={value}
      style={{
        width: `${width}%`
      }}
    />
  );
};
