import { withFormik } from 'formik';

import { ITheme, PageProps } from 'types';
import { getErrorMessage } from 'utilities';

import { ThemeForm } from '..';

export const ThemeFormWrapper = withFormik<ITheme & PageProps<ITheme>, any>({
  // Transform outer props into form values
  mapPropsToValues: ({ theme, invoices, residential }): ITheme => {
    return {
      theme,
      invoices,
      residential
    };
  },
  handleSubmit: async (props, formik) => {
    const { name, endpoint, values, ...rest } = props;
    const {
      props: { onSubmit, onSuccessToast, onFailureToast }
    } = formik;

    if (onSubmit) {
      try {
        await onSubmit(rest);
        onSuccessToast && onSuccessToast();
      } catch (error) {
        const err = error as any;
        const msg = getErrorMessage(error) || err.message;
        onFailureToast && onFailureToast(msg);
      }
    }
  }
})(ThemeForm);
