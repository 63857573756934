import styled from 'styled-components';
import { ReactSortable } from 'react-sortablejs';

export const ReactSortableWrapper = styled(ReactSortable)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const BlockItemWrapper = styled.div`
  width: 50%;
  flex-grow: 1;
  justify-content: space-between;

  &:nth-child(even) {
    padding-left: 0.5rem;
  }

  &.sortable-ghost > .card {
    background-color: #bee3f8;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
`;