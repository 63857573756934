import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

export const EmailAddressCard = (props: any): JSX.Element => {
  const { email, handleChange, isSubmitting, remittance } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Email Addresses</CardHeader>

      <CardBody>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label for="contactInfo.email.contact" className="font-weight-bold">
                Contact Email Address
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.email.contact"
                name="contactInfo.email.contact"
                type="text"
                onChange={handleChange}
                value={email.contact}
              />
            </FormGroup>
          </Col>

          <Col xs={4}>
            <FormGroup>
              <Label for="contactInfo.email.autoMailer" className="font-weight-bold">
                Auto-Reply Email Address
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.email.autoMailer"
                name="contactInfo.email.autoMailer"
                type="text"
                onChange={handleChange}
                value={email.autoMailer}
              />
            </FormGroup>
          </Col>

          <Col xs={4}>
            <FormGroup>
              <Label for="contactInfo.email.remittance" className="font-weight-bold">
                Remittance Email Address
              </Label>

              <Input
                disabled={isSubmitting}
                id="contactInfo.remittance.email"
                name="contactInfo.remittance.email"
                type="text"
                onChange={handleChange}
                value={remittance.email}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
