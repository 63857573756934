import styled from 'styled-components';

export const Banner = styled.div`
  background-color: #f2f5f8;
  border: 2px solid #99a1a8;
  width: 100%;
  border-radius: 4px;
  padding: 0.5rem 0;
  text-align: center;
  color: #40474e;
  font-weight: 700;
  margin-bottom: 1rem;
`;
