import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useConfigContent } from 'hooks';
import { IAutoAllocation } from 'types';
import { onSuccessToast, onFailureToast } from 'utilities';

import { update } from '../effects';
import { AutoAllocationsFormWrapper } from './components';

export const AutoAllocations = (props: { name: string }): JSX.Element => {
  const { data, error, loading } = useConfigContent(CONFIGURATION_ENDPOINTS.AUTO_ALLOCATION)();

  const dispatch = useDispatch<AppDispatch>();
  const onSubmit = async (values: IAutoAllocation) => {
    return await dispatch(update(CONFIGURATION_ENDPOINTS.AUTO_ALLOCATION, values));
  };
  const useToastsHook = useToasts();

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <AutoAllocationsFormWrapper
          values={data}
          endpoint={CONFIGURATION_ENDPOINTS.AUTO_ALLOCATION}
          name={props.name}
          onSubmit={onSubmit}
          onSuccessToast={onSuccessToast.bind(null, useToastsHook)}
          onFailureToast={onFailureToast.bind(null, useToastsHook)}
          {...data}
        />
      )}
    </ContentWrapper>
  );
};
