import { SyntheticEvent, useState } from 'react';

async function asyncNoop() { /* 'asyncNoop' is intentionally left empty */ }

export interface IUseForm<T> {
  error: undefined;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: SyntheticEvent) => void;
  isSubmitting: boolean;
  setValues: React.Dispatch<React.SetStateAction<T>>;
  success: boolean;
  values: T;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<undefined>>;
}

export function useForm<T>(
  initialValues: T,
  onSubmit = asyncNoop,
  onSuccess = (_res: any) => {},
  onFailure = (_err: any) => {}
): IUseForm<T> {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<T>(initialValues);
  const [error, setError] = useState<undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    setError(undefined);
    setIsSubmitting(true);

    onSubmit()
      .then(
        response => {
          setIsSubmitting(false);
          setSuccess(true);
          onSuccess(response);
        },
        (error: any) => {
          setIsSubmitting(false);
          setError(error);
          onFailure(error);
        }
      )
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (success) {
      setSuccess(false);
    }

    if (error) {
      setError(undefined);
    }

    setValues(values => {
      const input = event.target;

      return {
        ...values,
        [input.name]: input.value
      };
    });
  };

  return {
    error,
    handleInputChange,
    handleSubmit,
    isSubmitting,
    setValues,
    success,
    values,
    setSuccess,
    setError
  };
}
