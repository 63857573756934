import React from 'react';
import { Toggle } from './ToggleSwitch.styles';

type ToggleSwitchProps = {
  toggleOn: boolean;
  name: string;
  id: string;
  handleChange: (arg1: any) => void;
  disabledToggle?: boolean;
  theme: string;
};

const ToggleSwitch = (props: ToggleSwitchProps): JSX.Element | null => {
  const { toggleOn, name, id, handleChange, disabledToggle, theme } = props;
  const checkedStyleInner = toggleOn ? { marginLeft: '-100%' } : { marginLeft: '0' };
  const checkedStyleSwitch = toggleOn
    ? { right: '4px', backgroundColor: 'white' }
    : { right: '16px', backgroundColor: '#576068' };
  return (
    <Toggle>
      <div className="toggle-switch">
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          id={id}
          checked={toggleOn}
          onChange={(e) => handleChange(e.target.checked)}
          disabled={disabledToggle}
        />
        {id ? (
          <label
            className="toggle-switch-label"
            htmlFor={id}
            style={
              toggleOn
                ? { border: `2px solid ${theme === 'green' ? '#42a262' : '#0072B7'}` }
                : { border: '2px solid #576068' }
            }
          >
            <span style={checkedStyleInner} className={`toggle-switch-inner toggle-${theme}`} />
            <span style={checkedStyleSwitch} className="toggle-switch-switch" />
          </label>
        ) : null}
      </div>
    </Toggle>
  );
};

export default ToggleSwitch;
