import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type IconProps = {
  icon: IconProp;
};

const Icon = (props: IconProps): JSX.Element => (
  <FontAwesomeIcon icon={props.icon} fixedWidth style={{ alignItems: 'center', fontSize: '1rem' }} />
);

export default Icon;
