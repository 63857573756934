import { IAssociatedUtility, IAssociatedUtilityFormValues } from 'types';
import { Api } from 'api';
import { BaseService } from './BaseService';

export interface IAssociatedUtilitiesJSON {
  associatedUtilities: IAssociatedUtility[];
}

export class AssociatedUtilityService extends BaseService {
  constructor(api: Api) {
    super(api, '/associated-utility');
  }

  // TODO: how does inheritence work with different interface declarations?
  public fetchAll() {
    return this.get<IAssociatedUtilitiesJSON>(this.baseUrl);
  }

  public update(associatedUtility: IAssociatedUtility) {
    return this.put<IAssociatedUtility>(this.baseUrl, associatedUtility);
  }

  public create(associatedUtility: IAssociatedUtilityFormValues) {
    return this.post<IAssociatedUtility>(this.baseUrl, associatedUtility);
  }

  public remove(id: string): Promise<any> {
    return this.delete(`${this.baseUrl}/${id}`);
  }
}
