import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { BlockItem, ContentWrapper } from 'components';
import { useResidentialContent } from 'hooks';
import { IPageBlock } from 'types';

import { updateBlocks } from '../effects';
import * as Styles from './HomePageBlocks.styles';

interface Block extends IPageBlock {
  id: string;
}

export const HomePageBlocks = (): JSX.Element => {
  const { data, error, loading } = useResidentialContent();
  const { content } = data;

  const [blocks, setBlocks] = useState<Block[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (content?.homepage?.blocks) {
      setBlocks(content?.homepage?.blocks.map((b: any) => {
        return ({
          id: shortid.generate(),
          ...b
        });
      }))
    }
    // eslint-disable-next-line
  }, [content, content?.homepage, content?.homepage?.blocks, setBlocks]);

  function onEnd() {
    return dispatch(updateBlocks(blocks));
  }

  function onSave(id: string, block: IPageBlock) {
    const index = blocks.findIndex(block => block.id === id);
    const updatedBlocks = [
      ...blocks.slice(0, index),
      {
        ...blocks[index],
        ...block
      },
      ...blocks.slice(index + 1)
    ];

    return dispatch(updateBlocks(updatedBlocks)).then(() => {
      return setBlocks(updatedBlocks);
    });
  }

  function onDelete(id: string) {
    const index = blocks.findIndex(block => block.id === id);
    const updatedBlocks = blocks.filter((_block, i) => i !== index);

    return dispatch(updateBlocks(updatedBlocks)).then(() => {
      return setBlocks(updatedBlocks);
    });
  }

  function handleAdd() {
    const updatedBlocks = [
      ...blocks,
      {
        body: '',
        id: shortid.generate(),
        title: ''
      }
    ];

    setBlocks(updatedBlocks);
  }

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(content) && (
        <>
          <Styles.ReactSortableWrapper handle=".handle" onEnd={onEnd} animation={250} list={blocks} setList={setBlocks}>
            {blocks.map(block => (
              <Styles.BlockItemWrapper key={block.id}>
                <BlockItem body={block.body} title={block.title} id={block.id} onDelete={onDelete} onSave={onSave} />
              </Styles.BlockItemWrapper>
            ))}
          </Styles.ReactSortableWrapper>

          <div className="text-center">
            <Button color="link" className="text-bold" onClick={handleAdd}>
              Add New Block
            </Button>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};
