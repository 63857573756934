import { AppThunk } from 'app/store';
import { 
  IFaqItem, 
  IPageBlock,
  IResidentialHomepage,
  PageKey,
 } from 'types';

export function updatePage(pageKey: PageKey, values: IPageBlock): AppThunk<Promise<void>> {
  return async function updatePageThunk(_dispatch, _getState, api) {
    try {
      const response = await api.residential.updatePage(pageKey, values);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateHomePage(values: Partial<IResidentialHomepage>): AppThunk<Promise<void>> {
  return async function updateHomePageThunk(_dispatch, _getState, api) {
    try {
      const response = await api.residential.updateHomePage(values);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateHomePageForm(values: Partial<IPageBlock>): AppThunk<Promise<void>> {
  return async function updateHomePageFormThunk(_dispatch, _getState, api) {
    try {
      const response = await api.residential.updateHomePageForm(values);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateFaqs(faqs: IFaqItem[]): AppThunk<Promise<void>> {
  return async function updateFaqsThunk(_dispatch, _getState, api) {
    try {
      const updatedFaqs = faqs.filter(faq => faq.title.trim() !== '' && faq.content.trim() !== '');
      const response = await api.residential.updateFaqs(updatedFaqs);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateBlocks(blocks: IPageBlock[]): AppThunk<Promise<void>> {
  return async function updateBlocksThunk(_dispatch, _getState, api) {
    try {
      const updatedBlocks = blocks.filter(
        ({ body, title }) => title && title.trim() !== '' && body && body.trim() !== ''
      );
      const response = await api.residential.updateHomePageBlocks(updatedBlocks);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
