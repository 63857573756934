import React from 'react';
import { Button, FormGroup, Label, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { IMAGE_PURPOSE } from 'api';
import { ColorPicker, UploadImageCard } from 'components';


export const PortalCard = (props: any) => {
  const { 
    handlePreview, 
    onPostFile, 
    invoices,
    isPreviewing, 
    isSubmitting, 
    setFieldValue, 
    theme, 
    setFieldError, 
    errors 
  } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Portal</CardHeader>

      <CardBody>
        <Row>
          <UploadImageCard
            error={errors?.theme?.mainLogoUrl}
            fieldKey="theme.mainLogoUrl"
            imageUrl={theme.mainLogoUrl}
            label="Logo (400x400)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.MainLogo}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />

          <UploadImageCard
            error={errors?.invoices?.invoiceLogoUrl}
            fieldKey="invoices.invoiceLogoUrl"
            imageUrl={invoices.invoiceLogoUrl}
            label="Invoice Logo (400x400)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.InvoiceLogo}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />

          <UploadImageCard
            error={errors?.theme?.backgroundImageUrl}
            fieldKey="theme.backgroundImageUrl"
            imageUrl={theme.backgroundImageUrl}
            label="Background Image (1440x960)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.BackgroundImage}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />

          <UploadImageCard
            error={errors?.theme?.faviconLogoUrl}
            fieldKey="theme.faviconLogoUrl"
            imageUrl={theme.faviconLogoUrl}
            label="Favicon (32x32)"
            onPostFile={onPostFile}
            purposeKey={IMAGE_PURPOSE.Favicon}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
          />          
        </Row>

        <Row>
          <Col xs="3">
            <FormGroup>
              <Label for="theme-rimaryColor" className="font-weight-bold">
                Primary Color
              </Label>

              <ColorPicker
                id="theme-primaryColor"
                onChange={(color: { hex: any }) => setFieldValue('theme.primaryColor', color.hex)}
                color={theme.primaryColor}
              />
            </FormGroup>

            <FormGroup>
              <Label for="theme-lightPrimaryColor" className="font-weight-bold">
                Primary Color (Light)
              </Label>

              <ColorPicker
                id="theme-lightPrimaryColor"
                onChange={(color: { hex: any }) => setFieldValue('theme.lightPrimaryColor', color.hex)}
                color={theme.lightPrimaryColor}
              />
            </FormGroup>

            <FormGroup className="mb-0">
              <Label for="theme-secondaryColor" className="font-weight-bold">
                Secondary Color
              </Label>

              <ColorPicker
                id="theme-secondaryColor"
                onChange={(color: { hex: any }) => setFieldValue('theme.secondaryColor', color.hex)}
                color={theme.secondaryColor}
              />
            </FormGroup>
          </Col>

          <Col xs="3">
            <FormGroup>
              <Label for="invoices.invoiceHeaderBackgroundColor" className="font-weight-bold">
                Invoice Header Background Color
              </Label>

              <ColorPicker
                id="invoices-invoiceHeaderBackgroundColor"
                onChange={(color: { hex: any }) => setFieldValue('invoices.invoiceHeaderBackgroundColor', color.hex)}
                color={invoices.invoiceHeaderBackgroundColor}
              />
            </FormGroup>

            <FormGroup className="mb-0">
              <Label for="invoices.invoiceHeaderFontColor" className="font-weight-bold">
                Invoice Header Font Color
              </Label>

              <ColorPicker
                id="invoices-invoiceHeaderFontColor"
                onChange={(color: { hex: any }) => setFieldValue('invoices.invoiceHeaderFontColor', color.hex)}
                color={invoices.invoiceHeaderFontColor}
              />
            </FormGroup>

            <FormGroup>
              <Button
                block
                color="primary"
                className="font-weight-bold"
                disabled={isSubmitting || isPreviewing}
                onClick={handlePreview}
                style={{ marginTop: '45px' }}
              >
                {isPreviewing ? 'Downloading...' : 'Download Invoice Preview'}
              </Button>
              <small>Changes must be saved to appear in preview download.</small>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};