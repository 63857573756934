import { IAutoAllocation, PageProps } from 'types';
import { withFormik } from 'formik';
import { getErrorMessage } from 'utilities';
import { AutoAllocationsForm } from '..';

export const AutoAllocationsFormWrapper = withFormik<IAutoAllocation & PageProps<IAutoAllocation>, any>({
  // Transform outer props into form values
  mapPropsToValues: ({ decomposer, preferenceWeights }): IAutoAllocation => {
    return {
      decomposer,
      preferenceWeights
    };
  },

  handleSubmit: async (props, formik) => {
    const { name, endpoint, values, ...rest } = props;
    const {
      props: { onSubmit, onSuccessToast, onFailureToast }
    } = formik;

    if (onSubmit) {
      try {
        rest.decomposer.minimumChunkPercent = rest.decomposer.minimumChunkPercent * 100;
        await onSubmit(rest);
        onSuccessToast && onSuccessToast();
      } catch (error) {
        const err = error as any;
        const msg = getErrorMessage(error) || err.message;
        onFailureToast && onFailureToast(msg);
      }
    }
  }
})(AutoAllocationsForm);
