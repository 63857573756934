import styled from 'styled-components';

export const StatsWrapper = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export const Stat = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-bottom: 1px solid #cad0d6;
  padding: 0.5rem 0;

  .label {
    font-size: 16px;
  }

  .value {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
`;
