import React from 'react';
import { Alert, Card, CardBody, Col, FormGroup, Label } from 'reactstrap';

import { Drop } from './components';
import * as Styles from './UploadImageCard.styles';

export const UploadImageCard = (props: any): JSX.Element | null => {
  const { error, fieldKey, imageUrl, label, onPostFile, purposeKey, setFieldError, setFieldValue } = props;

  if (!onPostFile) {
    return null;
  }

  return (
    <Col tag={FormGroup} xs="3">
      <Label for={fieldKey} className="font-weight-bold">
        {label}
      </Label>
      <Card>
        <Styles.ImagePreview>
          <img width="100%" src={imageUrl} alt="Background" />
        </Styles.ImagePreview>

        <CardBody>
          <Drop
            onSubmit={async formData => {
              try {
                const response = await onPostFile(formData, purposeKey);
                setFieldValue(fieldKey, response.data.url);
              } catch (error) {
                const err = error as any;
                setFieldError(fieldKey, err.message ? err.message : 'There was an error.');
              }
            }}
          />

          {error && (
            <Alert color="danger" className="mt-3 mb-0">
              {error}
            </Alert>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
