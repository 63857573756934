import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

export const ProjectTimeline = () => {
  return (
    <Card>
      <CardHeader className="font-weight-bold">Project Timeline</CardHeader>
      <CardBody className="pb-5">
        <p className="font-weight-bold">
          Project timelines will be displayed when dates or estimates have been added at the Project level for the
          following milestones.
        </p>
        <ul className="pl-4">
          <li>Project Under Construction</li>
          <li>Project Complete and Energy Production Begins</li>
        </ul>
      </CardBody>
    </Card>
  );
};
