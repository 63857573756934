import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { api } from 'app/store';
import { IAddUserValues, IUser } from 'types';

export function useUsers() {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const { addToast } = useToasts();

  function addNewUser(values: IAddUserValues) {
    return api.account.addUser(values).then(
      (response: any) => {
        setUsers([
          ...users,
          {
            ...response
          }
        ]);

        addToast('New user added successfully.', { appearance: 'success' });

        return Promise.resolve(response);
      },
      error => {
        addToast('There was an error adding the new user. Please try again.', { appearance: 'error' });

        return Promise.reject(error);
      }
    );
  }

  useEffect(() => {
    setError(undefined);
    setUsers([]);
    setLoading(true);

    api.account
      .fetchUsers()
      .then(setUsers, setError)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return {
    addNewUser,
    error,
    loading,
    users
  };
}
