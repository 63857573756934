import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { reducer as session, shardReducer as shard } from 'utilities';


export const reducer = combineReducers({
  session,
  shard
});


export const store = configureStore({
  reducer: reducer,
});

export type RootState = ReturnType<typeof reducer>;
