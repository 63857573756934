import React from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col } from 'reactstrap';

export const StatusCard = (props: any): JSX.Element => {
  const { handleChange, status } = props;

  return (
    <Card className="mb-3">
      <CardHeader tag="h5">Status</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label xs={2} for="status" className="font-weight-bold">
            Status
          </Label>
          <Col xs={4}>
            <select
              className="form-control"
              id="passwordRequirements.requiredLength"
              name="passwordRequirements.requiredLength"
              onChange={handleChange}
              value={status}
            >
              <option value={0}>Active</option>
              <option value={1}>Pending</option>
              <option value={2}>Inactive</option>
            </select>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
