import { AppThunk } from 'app/store';
import { CONTENT_ENDPOINTS } from 'api';
import { ContentEndpointInterfaces } from 'types';

export enum ContentType {
  ResidentialSignup = 1,
  PrivacyPolicy = 1,
  Faqs = 2,
  Tos = 3,
  InitialDashboard = 4,
}

export function update<A extends CONTENT_ENDPOINTS>(
  path: A,
  contentType: ContentType,
  values: ContentEndpointInterfaces[A]
): AppThunk<Promise<void>> {
  return async function updatePageThunk(_dispatch, _getState, api) {
    try {
      await api.content.update(path, {
        ...values,
        contentType,
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
