import { Api } from 'api';

interface TokenResponse {
  access_token: string;
  type: string;
  expires_in: number;
  refresh_token: string;
  userName: string;
  userId: string;
  role: string;
  issued: string;
  hostname: string;
  scope: string;
}

export class AuthService {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  public forgotPassword(username: string) {
    return this.api.request<any>({
      method: 'POST',
      uri: '/account/forgot-password',
      qs: {
        username,
      },
    });
  }

  public resetPassword(username: string, token: string, newPassword: string) {
    return this.api.request<any>({
      method: 'POST',
      uri: '/account/reset-password',
      body: JSON.stringify({
        username,
        token,
        newPassword,
        shouldReset: '',
      }),
    });
  }
}
