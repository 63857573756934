import styled from 'styled-components';

export const ShardListWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ActiveShard = styled.div`
  background-color: #f7fafc;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  font-weight: bold;
  cursor: pointer;
  text-align: left;

  &:hover,
  &.open {
    background-color: #edf2f7;
    border-color: #cbd5e0;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
  }
`;

export const Shards = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  border-top: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0.5rem 0;
  text-align: left;

  &.top {
    max-height: calc(100vh - 75px);
  }

  &.open {
    display: block;
    border-color: #cbd5e0;
  }
`;

export const Shard = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: #f7fafc;
  }
`;
