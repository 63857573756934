import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { AppDispatch } from 'app/store';
import { CONTENT_ENDPOINTS } from 'api';
import { ContentWrapper } from 'components';
import { useContent } from 'hooks';
import { IContent, Route } from 'types';
import { onSuccessToast, onFailureToast } from 'utilities';

import { ContentType, update } from '../effects';
import { PrivacyPolicyFormWrapper } from './components';

export const PrivacyPolicy = (props: Route) => {
  const { data, error, loading } = useContent(CONTENT_ENDPOINTS.PRIVACY_POLICY)();
  const dispatch = useDispatch<AppDispatch>();
  const useToastsHook = useToasts();

  const onSubmit = async (values: IContent) =>
    await dispatch(update(CONTENT_ENDPOINTS.CONTENT, ContentType.PrivacyPolicy, values));

  return (
    <ContentWrapper error={error} loading={loading}>
      {Boolean(data) && (
        <PrivacyPolicyFormWrapper
          values={data}
          endpoint={CONTENT_ENDPOINTS.PRIVACY_POLICY}
          name={props.name}
          onSubmit={onSubmit}
          onSuccessToast={onSuccessToast.bind(null, useToastsHook)}
          onFailureToast={onFailureToast.bind(null, useToastsHook)}
          {...data}
        />
      )}
    </ContentWrapper>
  );
};
