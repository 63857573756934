import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const NavigationWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16rem;
  padding: 1rem;
  background-color: #fff;
  border-right: 1px solid #cbd5e0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: auto;
`;

export const NavText = styled.span`
  flex-grow: 1;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const NavTitle = styled.h5`
  font-size: 0.75rem;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #718096;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
`;

interface NavIconProps {
  active?: boolean;
}

export const NavIcon = styled.div<NavIconProps>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  color: #4a5568;
`;

export const StyledRouterNavLink = styled(RouterNavLink)`
  &.active > ${NavIcon} {
    color: #fff;
  }
`;
