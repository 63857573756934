import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { sentenceCase } from 'change-case';

import { PercentRange, PercentRow } from 'components';
import { IAutoAllocation } from 'types';

export type DecomposerCardProps = {
  decomposer: IAutoAllocation["decomposer"];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => {}
}

export const DecomposerCard = (props: any): JSX.Element => {
  const { decomposer, setFieldValue } = props;

  return (
      <Card className="mb-3">
        <CardHeader tag="h5">Decomposer</CardHeader>

        <CardBody>
          <PercentRow
            text={sentenceCase('defaultChunkSize')}
            value={decomposer.defaultChunkSize}
          >
            <PercentRange
              min={5000}
              max={100000}
              step={10}
              onChange={(e: any) => setFieldValue('decomposer.defaultChunkSize', e.currentTarget.value)}
              value={decomposer.defaultChunkSize}
              name="defaultChunkSize"
            />
          </PercentRow>

          <PercentRow
            text={sentenceCase('minimumChunkPercent')}
            value={decomposer.minimumChunkPercent}
          >
            <PercentRange
              min={0.1}
              max={1}
              step={.01}
              onChange={(e:any) => setFieldValue('decomposer.minimumChunkPercent', e.currentTarget.value)}
              value={
                decomposer.minimumChunkPercent > 1
                  ? decomposer.minimumChunkPercent / 100
                  : decomposer.minimumChunkPercent
              }
              name="minimumChunkPercent"
            />
          </PercentRow>
        </CardBody>
      </Card>
  );
};
