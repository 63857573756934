import React from 'react';
import { FormGroup, Col } from 'reactstrap';

export type PercentRowProps = {
  text: string;
  value: number;
  children?: React.ReactNode
}

export const PercentRow = (props: PercentRowProps): JSX.Element => {
  const { text, value, children } = props;


  return (
    <FormGroup row>
      <Col xs="3" className="font-weight-bold">
        {text}
      </Col>

      <Col xs="2" className="text-right">
        {value}
      </Col>

      <Col xs="7">
        {children}
      </Col>
    </FormGroup>
  );
};
