import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActiveShardKey } from 'utilities';

export function makeUseRequest<T>(request: Function) {
  return function useRequest() {
    const [data, setData] = useState<T>({} as T);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const activeShardKey = useSelector(getActiveShardKey);

    useEffect(() => {
      setError(undefined);
      setData({} as T);
      setLoading(true);

      request()
        .then(setData, setError)
        .catch(setError)
        .finally(() => setLoading(false));
    }, [activeShardKey]);

    return {
      activeShardKey,
      data,
      error,
      loading
    };
  };
}
