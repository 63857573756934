import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { Button } from 'components';

type ResetModalProps = {
  isOpen: boolean;
  loading: boolean;
  onCancel?: (args: any) => void;
  onSubmit?: (args: any) => void;
}

export const ResetModal = ({ isOpen, loading, onCancel, onSubmit }: ResetModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>Resetting sandbox data is a destructive action. All existing data will revert to default data. Previous work will not be saved.</ModalBody>
      <ModalFooter>
        <Button color="link" onClick={onCancel} disabled={loading}>Cancel</Button>
        <Button color="danger" onClick={onSubmit} loading={loading}>Reset Sandbox Data</Button>
      </ModalFooter>
    </Modal>
  )
}
