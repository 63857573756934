import React from 'react';
import { Label } from 'reactstrap';
import { sentenceCase } from 'change-case';

type CheckboxProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  field: string;
  label?: string;
  checked: boolean;
  [key: string]: any;
};

export const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { setFieldValue, field, label, checked, ...rest } = props;
  let fieldName = field;
  if (field.split('.').length) {
    fieldName = field.split('.')[field.split('.').length - 1];
  }

  return (
    <div className="custom-control custom-checkbox">
      <input
        checked={checked}
        onChange={e => setFieldValue(field, e.target.checked)}
        type="checkbox"
        className="custom-control-input"
        id={field}
        {...rest}
      />
      <Label className="custom-control-label" htmlFor={field}>
        {label ? label : sentenceCase(fieldName)}
      </Label>
    </div>
  );
};
