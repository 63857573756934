import React from 'react';
import { Nav, NavLink, NavItem, Card, CardHeader, CardBody } from 'reactstrap';
import { NavLink as Link, Outlet } from 'react-router-dom';

import { RouterProps } from 'types';

export const ResidentialHomePage = (props: RouterProps): JSX.Element => {
  const { routes } = props;

  return (
    <Card>
      <CardHeader>
        <Nav pills card>
          {routes.map(({ name, path }) => (
            <NavItem key={path}>
              <NavLink exact tag={Link} to={`${path}`}>
                {name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </CardHeader>

      <CardBody>
        <Outlet />
      </CardBody>
    </Card>
  );
};
