import { PrivacyPolicy, TermsOfUse, InitialDashboard } from 'pages';
import { Route } from 'types';

export const baseRoute = 'content';
export const privacyPolicyRoute = 'privacy-policy';
export const termsOfUseRoute = 'terms-of-use';
export const initialDashboardRoute = 'initial-dashboard';

export const routes: Route[] = [
  {
    component: PrivacyPolicy,
    key: 'privacy-policy',
    name: 'Privacy Policy',
    path: privacyPolicyRoute,
  },

  {
    component: TermsOfUse,
    key: 'terms-of-use',
    name: 'Terms of Use',
    path: termsOfUseRoute,
  },

  {
    component: InitialDashboard,
    key: 'initial-dashboard',
    name: 'Initial Dashboard',
    path: initialDashboardRoute,
  },
];
